

import React, { useState, useCallback } from "react";
import { Edit, SimpleForm, TextInput, Toolbar, useRecordContext, SaveButton, useRefresh, 
        TextField, LinearProgress, useGetOne, SelectInput, useNotify, ListButton, ReferenceInput,
        useRegisterMutationMiddleware, useDataProvider, TopToolbar, ReferenceField, Labeled, RadioButtonGroupInput
    } from 'react-admin';
import { Box, Typography, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useFormContext } from 'react-hook-form';
import { Sucursales } from "../../utils";
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import PrintIcon from '@mui/icons-material/Print';
import { DeleteRemision } from "../components/DeleteRemision";
import { EditableDatagridCustom } from "../components/EditableDatagridCustom";
import { OrdenesPendientesShow } from "../components/OrdenesPendientesShow";
import { TotalesComponent } from "../components/TotalesComponent";
import AlegraIcon from "../components/alegraIcon";
import { generatePDF } from "../functions/generateRemisionPDF";
import  {EditableTable}  from "../components/EditableTableContainer";



const RemisionEditActions = () => (
    <TopToolbar>
        <ListButton resource="remissions" label='Remisiones'/>
        <ListButton resource="sqlSalidas" label='Salidas'/>
    </TopToolbar>
);



const CustomToolbar = () => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const refresh = useRefresh();  // useRefresh hook to trigger a refresh

    const middleware = useCallback(async (resource, params, options, next) => {
        let { data } = params;
        let newParams = {};
        const userEdit = localStorage.getItem('user');
        //console.log('Params INSIDE MIDDLEWARE', params);
        //console.log('record INSIDE MIDDLEWARE', record);
        try {

            const response = await editAlegraRemision(data, record);
            
            if (response.data?.id) {
                notify('Remisión Editada en Alegra', { type: 'success' }, { smart_count: 1 });
            }

            const { productFact, factura, ...dataNew } = data;
            newParams = {
                id: dataNew.id,
                data: {
                    ...dataNew,
                    estado: dataNew.estado && dataNew.estado === 'Facturada'?'Facturada':'Editada',
                    fechaEdita: new Date().toISOString(),
                    usuarioEdita: userEdit,
                },
                previousData: { ...dataNew },
            };
        } catch (error) {
            //console.error('ERROR in createAlegraRemision', error);
            if (error.message === 'El id del ítem es un campo obligatorio') {
                notify('Error: Caja No Seleccionada', { type: 'error' },{ smart_count: 2 });
            } else {
                notify(`Error: ${error.message}`, { type: 'error' }, { smart_count: 2 });
            }
            return; // Exit if there is an error in creating Alegra Remision
        }
        

        try{
            await updateStock(data.itemsFact, record.itemsFact);
        }
        catch (error) {
            console.error('ERROR actualizando stock', error);
            notify(`Error: ${error.message}`, { type: 'error' }, { smart_count: 3 });
            return; // Exit if there is an error in updating stock
        }
        let rem;
         try {
            //console.log('newParams',newParams);
            rem = await next(resource, newParams, options);
         } catch (error) {
             console.error('ERROR actualizando Remision en Grafiflex Interactivo', error);
             notify(`Error: ${error.message}`, { type: 'error' }, { smart_count: 3 });
             return;
         }
         try{
            const res = await Promise.all(
                data.itemsFact.map(async item => {
                    const productId = data.productFact.items.filter(pf => pf.idItem === item.idItem)[0]?.id;
                    const remision = await rem;
                    if (productId) {
                        const newItem = { ...item, vendedorID: data.vendedorID, ciudad: data.ciudadId, remisionID: data.id, id: productId};
                        //console.log('UPDATING NAME', newItem);
                        //console.log('PRODUCT ID', productId);
                        return dataProvider.update('productFacts', { id: productId, data: { ...newItem } });
                    }
                    else if(!productId){
                        const newItem = { ...item, vendedorID: remision.vendedorID||'' , ciudad: remision.ciudadId, remisionID: remision.id, remisionNro: remision.alegraNumeracion, clienteID:remision.clienteId, clienteName: remision.clienteName};
                        return dataProvider.create('productFacts', { data: { ...newItem } });
                    }
                })
            );
            const res2 = await Promise.all(
                data.productFact.items.map(async item => {
                    const found = data.itemsFact.find(it => it.idItem === item.idItem);
                    if (!found) {
                        //console.log('DELETING NAME', item);
                        return dataProvider.delete('productFacts', { id: item.id });
                    }
                })
            );
            
            } catch (error) {
            console.error('ERROR', error);
            notify(`Error ${error.message}`, {type:'error'}, { smart_count: 3 });
        }
        //refresh(); 

    }, [dataProvider, notify]);

    const updateStock = async (itemsNew, itemsOld) => {
        // Filter out items with invalid area or undefined 'caja'
        const validItemsOld = itemsOld.filter(
            item => item.area > 0 && item.caja !== undefined && item.caja !== null
        );
        const validItemsNew = itemsNew.filter(
            item => item.area > 0 && item.caja !== undefined && item.caja !== null
        );
    
        // Summarize total area per 'caja' for old and new items
        const cajasOld = validItemsOld.reduce((acc, item) => {
            const found = acc.find(it => it.caja === item.caja);
            if (found) {
                found.area += item.area;
            } else {
                acc.push({ caja: item.caja, area: item.area });
            }
            return acc;
        }, []);
    
        const cajasNew = validItemsNew.reduce((acc, item) => {
            const found = acc.find(it => it.caja === item.caja);
            if (found) {
                found.area += item.area;
            } else {
                acc.push({ caja: item.caja, area: item.area });
            }
            return acc;
        }, []);
    
        // Compute area differences per 'caja'
        const cajasMap = new Map();
    
        // Add areas from 'cajasOld' (stock increases)
        for (const cajaOld of cajasOld) {
            cajasMap.set(cajaOld.caja, (cajasMap.get(cajaOld.caja) || 0) + cajaOld.area);
        }
    
        // Subtract areas from 'cajasNew' (stock decreases)
        for (const cajaNew of cajasNew) {
            cajasMap.set(cajaNew.caja, (cajasMap.get(cajaNew.caja) || 0) - cajaNew.area);
        }
    
        // Convert map to array
        const cajas = Array.from(cajasMap.entries()).map(([caja, area]) => ({ caja, area }));
    
        // Update stocks
        for (const caja of cajas) {
            try {
                if (caja === null || caja.caja === undefined) continue;
    
                const stock = await dataProvider.getOne('stocks', { id: caja.caja });
                const newStock = {
                    ...stock.data,
                    areaRestante: stock.data.areaRestante + caja.area,
                };
                delete newStock.retales;
                await dataProvider.update('stocks', { id: caja.caja, data: newStock });
            } catch (error) {
                console.error(`ERROR updating stock for caja ${caja.caja}`, error);
                notify(
                    `Error updating stock for caja ${caja.caja}: ${error.message}`,
                    { type: 'error' }
                );
            }
        }
        refresh();
    };

    const editAlegraRemision = async (values, oldValues) => {
        const alegraJson = {
            documentName: 'remission',
            date: new Date(),
            dueDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            anotation: values.observaciones,
            client: { id: values.alegraClienteId },
            seller: values.vendedorAlegraId ? { id: values.vendedorAlegraId } : undefined,
            items: values.itemsFact.map(item => {
                let descripcion = '';
                if (item.ancho > 0 && item.largo > 0 && item.area > 0) {
                    descripcion = `${item.descripcion}\n${item.ancho} x ${item.largo}   Cant: ${item.cantidad}`;
                    if (item.odc && item.odc.trim().length > 0) {
                        descripcion = `${descripcion} - ODC:${item.odc}`;
                    }
                } else {
                    descripcion = item.descripcion;
                    if (item.odc) {
                        descripcion = `${descripcion} - ODC:${item.odc}`;
                    }
                }
                return {
                    id: item.alegraItemId,
                    reference: item.ordenVersion,
                    description: descripcion,
                    quantity: item.area > 0 ? item.area : item.cantidad,
                    price: item.valorUnitario,
                    tax: [{ id: item.iva ? 3 : 0 }],
                };
            }),
            costCenter: { id: Sucursales.find(sucursal => sucursal.id === values.ciudadId).costCenterId },
            warehouse: { id: Sucursales.find(sucursal => sucursal.id === values.ciudadId).alegraID },
        };
        //console.log('ALEGRA JSON', alegraJson);
        //console.log('oldValues', oldValues);
        if (oldValues.alegraId !== undefined && oldValues.alegraId !== null &&oldValues.estado === 'Facturada') {
            return alegraJson;
        }
        return dataProvider.update('alegraRemissions', { ...alegraJson, id: values.alegraId });
    };

    useRegisterMutationMiddleware(middleware);

    return (
        <Toolbar>
            <Grid container justifyContent="space-between">
                <Grid item xs={12} md={6}>
                    <SaveButton
                        key={'emitir'}
                        label="Actualizar Remisión"
                        icon={<SendAndArchiveIcon />}
                        alwaysEnable
                    />
                </Grid>
             
                <Grid item xs={12} md={5}>
                    {/* Additional content can go here */}
                </Grid>
                <Grid item xs={12} md={1}>
                    <DeleteRemision />
                </Grid>
            </Grid>
        </Toolbar>
    );
};



const EditTitle = () => {
    const record = useRecordContext();
    //console.log('RECORD INSIDE REMISION EDIT', record);

    return <span>Remisión {record ? `${record.alegraNumeracion}` : ''}</span>;    

};

const RemisionEdit = () => {
    const [ refreshDatagrid, setRefreshDatagrid ] = useState();
    
    const refetchData = (v) => {
        setRefreshDatagrid(v)
    }


    return (
        <Edit resource="remissions"  redirect='list' title={<EditTitle/>} actions={<RemisionEditActions/>} mutationMode="pessimistic">
            <SimpleForm toolbar={<CustomToolbar/>} >
                <Grid container spacing={1} justifyContent={'space-between'} alignContent={'stretch'}>
                    <Grid item xs={12} md={9} container direction='row' spacing={1} >
                        <Grid item xs={12} md={12}>
                            <Labeled>
                                <ReferenceField source = 'clienteId' reference='clientes'>
                                    <TextField source="razonSocial" label="Cliente"sx={{padding:'10px', fontSize: '25px'}} />
                                </ReferenceField>
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <SelectInput source="ciudadId" label="Ciudad"  choices={Sucursales} optionText='name' fullWidth/>
                        </Grid>
                        <Grid item xs={12} md={5}>
                        <ReferenceInput source='vendedorID' reference='usuarios'>
                                <SelectInput optionText={(r) => `${r.nombres} ${r.apellidos}`} label="Vendedor" fullWidth />
                        </ReferenceInput>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3} sx={{justifyContent: 'right'}}>
                        <NumeracionEstadoField/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <EditableDatagridCustom refreshDatagrid={refreshDatagrid}/>
                    </Grid>
                    {/* <Grid item xs={12} md={12}>
                        <EditableTable tableName='remission' />
                    </Grid> */}
                    <Grid item  xs={12} md={12} container  direction='row' justifyContent={'flex-end'} >
                        <Grid item xs={12} md={7} >
                            <TextInput source="observaciones"  fullWidth/>
                        </Grid>
                        <TotalesComponent changed={refetchData}/>
                    </Grid>
                </Grid>
            </SimpleForm>
           <OrdenesPendientesShow/>
        </Edit>
    );
};

export default RemisionEdit;

const NumeracionEstadoField = () => {
    const record  = useRecordContext()
    //console.log('RECORD inside remision', record);
    const { setValue, getValues } = useFormContext();
    const [stateSelectorOpen, setStateSelectorOpen] = useState(false);
    const { data,  isLoading2  } = useGetOne('alegraRemissions', {id:record?.alegraId});
    //console.log('DATA ALEGRA REMISSION', data);
    const { data: cliente,  isLoading  } = useGetOne('clientes', {id:record?.clienteId});
    
    //console.log('DATA ALEGRA REMISSION', data);
    let estado = getValues('estado');
    const alegraPdf = getValues('pdf');
    const alegraUrl = getValues('url');
    const dataRem = getValues();
    //console.log('DATA REM', dataRem);
    //estado = 'Anulada'
    let style = { Facturada: {backgroundColor: 'green', color: 'white'}, Anulada: {backgroundColor: '#c91104', color: 'white'}, Emitida: {backgroundColor: 'lightgrey', color: 'black'}, Editada: {backgroundColor: 'lightblue', color: 'black'}};

    //console.log('ESTADO', data.status);
    // if (data&&data.status === "closed") {
    //     if (data.status === "closed") {
    //         record.estado = "Facturada";
    //         //setValue('estado', 'Facturada');
    //     }
    // }
    // if (data&&data.status === "void") {
    //     record.estado = "Anulada";
    //     setValue('estado', 'Anulada');
    // }
    const handlePrint = (e) => {
        // Open data.pdf link in another window
        //window.open(alegraPdf, '_blank');
        generatePDF(dataRem, data?.client);

    }
    const handleOpen = (e) => {
        // Open data.url link in another window
        window.open(alegraUrl, '_blank');
    }
    const openStateSelector = (e) => {

        //console.log('STATE SELECTOR OPEN', stateSelectorOpen)
        setStateSelectorOpen(true);
        
    }   



    return (
        <Grid container direction='row' spacing={3} alignitems='stretch'>
            <Grid item xs={12} md={6} container direction='column' justifyContent='space-evenly'>
                <Grid item xs={12} md={6}  >
                    <Button type='button' onClick={(e)=>handlePrint(e)} endIcon={<PrintIcon />} variant='outlined' sx={{width:'100%', marginTop:'3px'}} disabled={isLoading}>
                        Imprimir
                    </Button>
                </Grid>
                <Grid item xs={12} md={6} >
                    <Button type='button' onClick={(e)=>handleOpen(e)} endIcon={<AlegraIcon />} variant='outlined' sx={{width:'100%'}} >
                        VER 
                    </Button>
                </Grid>
            </Grid>

            <Grid item xs={12} md={6} >
                <Button onClick={openStateSelector}>
                    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' ,boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.1)', width:'123px', marginBottom:'5px'}}>
                        <Typography variant="h6" style={{ fontSize: '24px',fontWeight: 'bold'}}>{record.alegraNumeracion}</Typography>
                        {isLoading ? <LinearProgress sx ={{width:'100px'}} />
                        :
                        <Box sx={{backgroundColor: style[estado].backgroundColor, width: '120%', padding:0 }} >
                            <Typography variant="h6" style={{ fontSize: '18px', fontWeight: 'bold', display:'flex', justifyContent:'center', width:'100%', color: style[estado].color}}>{estado}</Typography>
                        </Box>
                        }   
                        <Dialog open={stateSelectorOpen}>
                            <DialogTitle>
                                <Typography variant="h6" style={{ fontSize: '18px', fontWeight: 'bold', display:'flex', justifyContent:'center', width:'100%'}}>Cambiar Estado De Remision</Typography>
                            </DialogTitle>
                            <DialogContent>
                                <RadioButtonGroupInput source="estado" choices={[
                                    { id: 'Facturada', name: 'Facturada' },
                                    { id: 'Emitida', name: 'Emitida' },
                                    { id: 'Editada', name: 'Editada' },
                                    { id: 'Anulada', name: 'Anulada' },
                                ]} 
                                onChange={(e)=>{console.log('CHANGE', e); setValue('estado', e.target.value); setStateSelectorOpen(false);}}
                                />
                            </DialogContent>
                            
                        </Dialog>
                    </Box>
                </Button>
            </Grid>
        </Grid>
    );
};









