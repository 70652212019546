import React, {useEffect } from 'react';
import { List, Datagrid, TextField, DateField, Pagination, NumberField, ReferenceField, TopToolbar, ExportButton,
    useListContext, useUnselectAll, FilterList, FilterListItem, FunctionField, downloadCSV, ListBase, Title,ListToolbar,
    useGetList, useDataProvider, DeleteButton,
    SearchInput,
    FilterLiveSearch
} from 'react-admin';
import UserIcon from '@mui/icons-material/PersonOutline';
import Summary from './Summary';
 import { Card, CardContent, Divider, Grid, Typography, Stack, Box } from '@mui/material';
 import jsonExport from 'jsonexport/dist';


 const ciudadesMap ={
    "Mede": "ENVIGADO",
    "Barr": "BARRANQUILLA",
    "Cali": "CALI",
}

function getMonthDateRange(when) {
    const now = new Date();
    // Get the first day of the current month
    if(when === 'current'){
        const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);        
        const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        return {
            start: firstDay,
            end: lastDay
        };
    }
    if(when === 'last'){
        const firstDay = new Date(now.getFullYear(), now.getMonth()-1, 1);        
        const lastDay = new Date(now.getFullYear(), now.getMonth(), 0);
        return {
            start: firstDay,
            end: lastDay
        };
    }

   
}


const removeDuplicates = (data, uniqueKey) => {
    return data?data.reduce((accumulator, current) => {
        if (!accumulator.some((item) => item[uniqueKey] === current[uniqueKey])) {
        if(current[uniqueKey]){
            accumulator.push(current);
        }
        }
        return accumulator;
}, []):[];
};



const ListPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

const selectSource = (record) => {
    if(record.facturaID){
        return 'factura'
    }
    if(record.remisionID){
        return 'remision'
    }
    if(record.cotizaID){
        return 'cotiza'
    }
}

const selectReference = (record) => {
    if(record.facturaID){
        return 'facturas'
    }
    if(record.remisionID){
        return 'remissions'
    }
    if(record.cotizaID){
        return 'cotizacions'
    }
}

const exporter = records => {
    const recordExport = records.map(record => {
        const { remision, factura, cotiza, id, idItem,clienteID, remisionID, cotizaID, facturaID, odc, alegraItemId, iva, ordenVersion, vendedorID, updatedAt, ...data } = record; 
        return data;
    });
    
    jsonExport(recordExport, {
//        headers: ['id', 'title', 'author_name', 'body'] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'registros'); // download as 'posts.csv` file
    });
};

const TopToolbarCustom = () => (
    <TopToolbar>
        <ExportButton meta={{searchable:true, filterable: Filterable}}/>
    </TopToolbar>
);

//const ListFilters = [<TextInput label="Buscar" source="orden.eq" alwaysOn />]

const Filterable = ['ordenVersion', 'descripcion', 'clienteName', 'caja' ]

const ReportesList = () => {
    const user = localStorage.getItem('user');
    //console.log('USER', user)
    let canDelete;
    if(user === '3b68f505-d15a-4381-a11c-639e220a5d2f'){
        canDelete = true;
    }else {
        canDelete = false;
    }


    return(
    <ListWithSummary debounce={500}  
                    pagination = {<ListPagination />} 
                    title={'Reportes'} 
                    perPage={50} 
                    aside={<FilterSideBar/> } 
                    queryOptions={{meta:{searchable:true, agg:Aggregations, filterable: Filterable}}} 
                    exporter={exporter} 
                    actions={<TopToolbarCustom />}

                    >
        <Datagrid   bulkActionButtons={false}     sx={ listStyle}   >
            <TextField source="ordenVersion" label='Orden'/>
            <TextField source='clienteName' label='Cliente' />
            {/*<TextField source="material" />*/}
            <FunctionField label='Doc' render={(r)=>{
                //console.log('RECORD', r.facturaID, r.remisionID, r.cotizaID)
                if(r?.facturaID){
                    return r?.factura?.alegraNumeracion || ''
                }
                else if(r?.remisionID){
                    return r?.remision?.alegraNumeracion||''
                }
                else{
                    return r?.cotiza?.consecutivo || ''
                }
            
            }} />
            <TextField source="ciudad" />
            <TextField source="caja" />
            <TextField source="descripcion" noWrap />
            <NumberField source="cantidad" label='Qty'/>
            <NumberField source="area" />
            <NumberField source="valorTotal" />
            <ReferenceField label="vendedor" source="vendedorID" reference="usuarios">
                <FunctionField source="nombres" render={(v)=>(`${v.nombres} ${v.apellidos}`)} />
            </ReferenceField>
            <DateField source="createdAt" label='Fecha'  showTime={true} options={ {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'}}/>
            {
                canDelete?
                <DeleteButton label={''}  />
                :null
            }
        </Datagrid>
    </ListWithSummary>
)};

const ListWithSummary = ({ children, actions, filters, title, aside, data,...props }) => (
    <ListBase {...props}>
        <Title title={title}/>
        <Grid container direction='row' spacing={1} alignItems='flex-start'> 
            <Grid item md={1.5}>
                    <Card>
                        {aside}
                    </Card>
            </Grid>
            <Grid item md={10.5} container direction={'column'} spacing={2}>
                <Grid item  md={12}>
                    <ListToolbar
                        filters={filters}
                        actions={actions}
                    />
                    <Summary filterable={Filterable} agg={Aggregations} resource='productFacts'/>
                </Grid>
                <Grid item md={10.5} style={{ alignItems: 'flex-start' }}>
                    <Card>
                        {children}
                    </Card>            
                </Grid>
            </Grid>
        </Grid>
        <Pagination />
    </ListBase>
);

const FilterSideBar = (props) => {
    const { data, filterValues, setFilters } = useListContext();

    const unselectAll = useUnselectAll('sqlSalidas');

    const { data: vendedores, isLoading } = useGetList('usuarios', {filter: {}, pagination:{page:1,perPage:999}} );
    const vendedoresFilter = vendedores?.filter(item => item.alegraVendedorId);
    const thisMonthStartStr = getMonthDateRange('current').start.toISOString().substring(0, 10);
    const thisMonthEndStr = getMonthDateRange('current').end.toISOString().substring(0, 10);
    const lastMonthStartStr = getMonthDateRange('last').start.toISOString().substring(0, 10);
    const lastMonthEndStr = getMonthDateRange('last').end.toISOString().substring(0, 10);
    //console.log('Vendedores', vendedoresFilter)
    useEffect(() => {
        //console.log('Filter Values', filterValues);
        if (filterValues) {
            unselectAll()
            }
        else{
        }
    }, [filterValues, unselectAll]);


    return (
        <CardContent>  
            <FilterList label="Tipo" >
                <FilterLiveSearch source="q" key='q' />
            </FilterList> 
            <FilterList label="Documentos" >
                <FilterListItem  key = 'facturas'       label={"Facturas"}     value = {{facturaID: {exists: true} }}   sx={{RaFilterListItem:{fontSize:'10px'}}}/>
                <FilterListItem  key = 'remisiones'     label={"Remisiones"}   value = {{remisionID: {exists: true} }}  sx={{RaFilterListItem:{fontSize:'10px'}}}/>
                <FilterListItem  key = 'remisionesSinFact'     label={"Remisiones Sin Facturar"}   value = {{and:{remisionID: {exists: true}, facturaID: {exists: false}} }}  sx={{RaFilterListItem:{fontSize:'10px'}}}/>
                <FilterListItem  key = 'cotizaciones'   label={"Cotizaciones"} value = {{cotizaID: {exists: true} }}   sx={{RaFilterListItem:{fontSize:'10px'}}}/>
            </FilterList> 
            <FilterList label="Tipo" >
                <FilterListItem  key = 'plancha'       label={"Planchas"}     value = {{type: {eq: 'fotopolimero'} }}   sx={{RaFilterListItem:{fontSize:'10px'}}}/>
                <FilterListItem  key = 'prueba'     label={"Prueba de Contrato"}   value = {{type: {eq: 'prueba'} }}  sx={{RaFilterListItem:{fontSize:'10px'}}}/>
                <FilterListItem  key = 'otros'   label={"Otras"} value = {{and:[{type:{ne: 'fotopolimero'}}, {type:{ ne:'prueba'}}]}}   sx={{RaFilterListItem:{fontSize:'10px'}}}/>
            </FilterList> 
            <FilterList label="Ciudad" >
                <FilterListItem  key = 'envigado'       label={"Envigado"}       value = {{ciudad: {eq: "ENVIGADO"} }}      sx={{RaFilterListItem:{fontSize:'10px'}}}/>
                <FilterListItem  key = 'barranquilla'   label={"Barranquilla"}   value = {{ciudad: {eq: "BARRANQUILLA"} }}  sx={{RaFilterListItem:{fontSize:'10px'}}}/>
                <FilterListItem  key = 'cali'           label={"Cali"}           value = {{ciudad: {eq: "CALI"} }}          sx={{RaFilterListItem:{fontSize:'10px'}}}/>
            </FilterList> 
            <FilterList label="Fecha" >
                <FilterListItem  key = "createdAt" label={"Este Mes"}       value = {{createdAt: {gte: thisMonthStartStr, lte: thisMonthEndStr}}}  sx={{RaFilterListItem:{fontSize:'10px'}}} />
                <FilterListItem  key = "createdAt2" label={"Mes Anterior"}  value = {{createdAt: {gte: lastMonthStartStr, lte: lastMonthEndStr}}} sx={{RaFilterListItem:{fontSize:'10px'}}}/>
            </FilterList> 
            <FilterList label="Vendedores" icon={<UserIcon/>}>
                <FilterListItem sx={{RaFilterListItem:{fontSize:'10px'}}} key = 'externos' label={"Todos"}  value = {{vendedorID: {gt: '0'}}} />
                {        
                    vendedoresFilter?vendedoresFilter.map((item)=>(
                            <FilterListItem key={item.id} label={`${item.nombres} ${item.apellidos}`}  value={{ vendedorID: {eq:item.id} }}  />
                    )): <FilterListItem  label="Sin Usuarios en el Rango" />
                }
            </FilterList>
        </CardContent>
    )
}

const Aggregations = [
    {field:'valorTotal', name:'TotalPesos', type:'sum'},
    {field:'area', name:'TotalArea', type:'sum'},
]



const listStyle = {
    "& .column-descripcion": { 
          maxWidth: "250px",
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
      },
      "& .column-clienteName": { 
          maxWidth: "180px",
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
      },
      "& .column-ordenVersion": { 
          maxWidth: "100px",
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'

      },
      "& .column-ciudad": { 
          maxWidth: "90px",
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'

      },
      "& .column-cantidad": { 
          maxWidth: "50px",
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'

      },
      "& .column-area": { 
          maxWidth: "80px",
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'

      },
      "& .column-valorTotal": { 
          maxWidth: "90px",
          whiteSpace: 'nowrap',

      },
      "& .column-caja": { 
          maxWidth: "150px",
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'

      },
      "& .column-createdAt": { 
          maxWidth: "100px",
       

      },
  }

export default ReportesList;