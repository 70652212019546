import React, { act, useEffect, useState } from "react";
import CustomEditableTable from "./CustomEditableTable";
import { useFormContext } from "react-hook-form";
import { useDataProvider, useGetList } from "react-admin";
import { useNotify, useRefresh } from "react-admin";
import { type } from "@testing-library/user-event/dist/cjs/utility/index.js";

const referenceData = {
    materials: [{ id: '1', name: 'Material 1' }, { id: '2', name: 'Material 2' }],
    stocks: [{ id: 'A', name: 'Stock A' }, { id: 'B', name: 'Stock B' }],
};

export const EditableTable = (props) => {
    
    const { setValue, getValues } = useFormContext();
    const itemsFact = getValues("itemsFact");
    const formValues = getValues();
    const [selectedMaterial, setSelectedMaterial] = useState({});
    const [queryMaterials, setQueryMaterials] = useState("");
    console.log("itemsFact", itemsFact);
    const [referenceData, setReferenceData] = useState([]);
    const { data: materials, isLoading: loadingMaterials  } = useGetList("materials", 
        {   
            filter: queryMaterials ? { id: { matchPhrasePrefix: queryMaterials } } : {},
            pagination:{ page: 1, perPage: 30 },
            meta: {searchable: true}
        },
    );
    const { data: stocks, isLoading: loadingStocks  } = useGetList("stocks",{
                                                                                filter: { 
                                                                                    materialID: {eq:selectedMaterial.value},
                                                                                    activa: {eq:true},
                                                                                    ingresada: {eq:true}
                                                                                },
                                                                                pagination:{ page: 1, perPage: 30 },
                                                                                sort: { field: 'consecutivo', order: 'DESC'},
                                                                                meta: {searchable: true}
                                                                            },
                                                                             {options:{enabled: selectedMaterial !== null}
                                                                            });
    
    useEffect(() => {
        console.log("useEffect", itemsFact, referenceData);
        itemsFact && referenceData && referenceData?.materials && referenceData?.stocks && itemsFact.map((item) => {
            if (!referenceData.materials.find((material) => material.id === item.material)) {
                setReferenceData({
                    ...referenceData,
                    materials: [...referenceData.materials, { id: item.material, name: item.material, type: item.type }],
                });
            }
            if (!referenceData.stocks?.find((stock) => stock.id === item.caja)) {
                setReferenceData({
                    ...referenceData,
                    stocks:  [...referenceData.stocks,{ id: item.caja, name: item.caja.split('-')[2] }],
                });
            }
        }
        );
    }
    , [itemsFact]);

    

    useEffect(() => {
        if (stocks) {
            setReferenceData(prev=>({
                ...prev,
                stocks:  stocks.map((stock) => ({
                    id: stock.id,
                    name: stock.consecutivo,
                })),
            }));
        }
    }
    , [stocks]);

    //map alegra categories

    
    useEffect(() => {
        const alegraCategories = [
            { id: '1', name: 'fotopolimero' },
            { id: '2', name: 'solvente' },
            { id: '3', name: 'prueba' },
            { id: '4', name: 'arte' },
            { id: '5', name: 'otro' },
        ]
        if (materials && Array.isArray(materials)) {
            setReferenceData(prev=>({
                ...prev,
                materials:  materials.map((material) => ({
                    id: material.id,
                    name: material.id,
                    type: alegraCategories.find((category) => category.id === material.alegraCategory)?.name,
                })),
            }));
        }
    }, [ materials ]);


    

    return (
        <CustomEditableTable
            {...props}
            initialRows={itemsFact} 
            loadingMaterials = {loadingMaterials}
            loadingStocks = {loadingStocks}
            referenceData={referenceData}
            setSelectedMaterial={setSelectedMaterial}
            columns={columns[props.tableName]}
            setQueryMaterials={setQueryMaterials}
            setReferenceData={setReferenceData}
        />
    );
    }


const columns = {
    remission: [
        { header: 'Orden', field: 'ordenVersion', type: 'text' , width: '50px', editable: true},
        { header: 'OC', field: 'odc', type: 'text', width: '50px', editable: true },
        { header: 'Descripción', field: 'descripcion', type: 'text' , width: '300px', editable: true},
        { header: 'Producto', field: 'material', type: 'autocomplete', reference: 'materials', optionText: 'id', width: '200px', editable: true},
        { header: 'Caja', field: 'caja', type: 'select', reference: 'stocks', optionText: 'name', width: '50px', editable: true },
        { header: 'Ancho', field: 'ancho', type: 'number' , width: '50px', editable: true},
        { header: 'Largo', field: 'largo', type: 'number' , width: '50px', editable: true},
        { header: 'Cantidad', field: 'cantidad', type: 'number' , width: '50px', editable: true},
        { header: 'Area', field: 'area', type: 'number', width: '50px', editable: false},
        { header: 'V. Unitario', field: 'valorUnitario', type: 'number', width: '50px', editable: true},
        { header: 'IVA', field: 'iva', type: 'boolean', width: '50px', editable: true},
        { header: 'Total', field: 'valorTotal', type: 'number', width: '50px', editable: false},
        ],
    invoice: [
        { header: 'Orden', field: 'ordenVersion', type: 'text' , width: '50px', editable: true},
        { header: 'OC', field: 'odc', type: 'text', width: '50px', editable: true },
        { header: 'Descripción', field: 'descripcion', type: 'text' , width: '300px', editable: true},
        { header: 'Producto', field: 'material', type: 'autocomplete', reference: 'materials', optionText: 'id', width: '200px', editable: true },
        { header: 'Caja', field: 'caja', type: 'select', reference: 'stocks', optionText: 'name', width: '50px', editable: true },
        { header: 'Ancho', field: 'ancho', type: 'number' , width: '50px', editable: true},
        { header: 'Largo', field: 'largo', type: 'number' , width: '50px', editable: true},
        { header: 'Cantidad', field: 'cantidad', type: 'number' , width: '50px', editable: true},
        { header: 'Area', field: 'area', type: 'number', width: '50px', editable: false},
        { header: 'V. Unitario', field: 'valorUnitario', type: 'number', width: '50px', editable: true },
        { header: 'IVA', field: 'iva', type: 'boolean', width: '50px', editable: true },
        { header: 'Total', field: 'valorTotal', type: 'number', width: '50px', editable: false},
    ],
    repo: [
        { header: 'Orden', field: 'ordenVersion', type: 'text' , width: '50px', editable: true},
        { header: 'Descripción', field: 'descripcion', type: 'text' , width: '300px', editable: true},
        { header: 'Producto', field: 'material', type: 'autocomplete', reference: 'materials', optionText: 'id', width: '200px', editable: true },
        { header: 'Caja', field: 'caja', type: 'select', reference: 'stocks', optionText: 'name', width: '50px', editable: true },
        { header: 'Ancho', field: 'ancho', type: 'number' , width: '50px', editable: true},
        { header: 'Largo', field: 'largo', type: 'number' , width: '50px', editable: true},
        { header: 'Cantidad', field: 'cantidad', type: 'number' , width: '50px', editable: true},
        { header: 'Area', field: 'area', type: 'number', width: '50px', editable: false},
        { header: 'V. Unitario', field: 'valorUnitario', type: 'number', width: '50px', editable: true},
        { header: 'IVA', field: 'iva', type: 'boolean', width: '50px', editable: true },
        { header: 'Total', field: 'valorTotal', type: 'number', width: '50px', editable: false},
    ], 
    cotizacion: [
        { header: 'Orden', field: 'ordenVersion', type: 'text' , width: '50px', editable: true},
        { header: 'OC', field: 'odc', type: 'text', width: '50px' , editable: true},
        { header: 'Descripción', field: 'descripcion', type: 'text' , width: '300px', editable: true},
        { header: 'Producto', field: 'material', type: 'autocomplete', reference: 'materials', optionText: 'id', width: '200px', editable: true},
        { header: 'Caja', field: 'caja', type: 'select', reference: 'stocks', optionText: 'name', width: '50px', editable: true},
        { header: 'Ancho', field: 'ancho', type: 'number' , width: '50px', editable: true},
        { header: 'Largo', field: 'largo', type: 'number' , width: '50px', editable: true},
        { header: 'Cantidad', field: 'cantidad', type: 'number' , width: '50px', editable: true},
        { header: 'Area', field: 'area', type: 'number', width: '50px', editable: false},
        { header: 'V. Unitario', field: 'valorUnitario', type: 'number', width: '50px' , editable: true},
        { header: 'IVA', field: 'iva', type: 'boolean', width: '50px', editable: true },
        { header: 'Total', field: 'valorTotal', type: 'number', width: '50px', editable: false},
    ]

}