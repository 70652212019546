

import React, {  useState, useCallback } from "react";
import { Create, SimpleForm, TextInput, Toolbar,SaveButton, 
         TextField, SelectInput, useNotify,
        useRegisterMutationMiddleware, useDataProvider, ReferenceField, Labeled,
        ReferenceInput,
        AutocompleteInput,
        AutocompleteArrayInput
        } from 'react-admin';
import { Grid } from '@mui/material';
import { Sucursales } from "../../utils";
import { OrdenesPendientesShow } from "../components/OrdenesPendientesShow";
import { CustomEditableTable } from "../components/CustomEditableTable";
import { TotalesComponent } from "../components/TotalesComponent";
import { TiempoPago } from "../../utils";
import ObservacionesCliente from "../components/ObservacionesCliente";
import  {EditableTable}  from "../components/EditableTableContainer";


const CustomToolbar = () => {
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const middleware = useCallback(async (resource, params, options, next) => {
        let { data } = params;
        //console.log('DATA INSIDE MIDDLEWARE', data);
        let newParams = {};
        const days = TiempoPago.find(tp => tp.id === data.formaPago).value || 0;
        const dueDate = new Date(new Date().setDate(new Date().getDate() + days));
  
        let newConsecutivo = 0
        const { clientData, ...fixedData } = data;

        ///GET Reposciones
        try{
            const response = await dataProvider.getList('reposicions', {filter:{}, pagination: {page: 1, perPage: 1}, sort: {field: 'consecutivo', order: 'DESC'}, meta: {searchable:true }})
            //console.log('RESPONSE', response);
            newConsecutivo = await parseInt(response.data[0]?.consecutivo) + 1 || 1;
            
        }
        catch(error){
            console.error('ERROR', error);
            notify(`Error Generando Consecutivo, Intente nuevamente ${error.message}`, {type:'error'}, { smart_count: 1 });
            return;
        }

        //////// Construct newParams
        if(newConsecutivo > 0){
            newParams = { ...params, 
                data: { ...fixedData, 
                    consecutivo: newConsecutivo,
                    estado: 'Emitida',
                    fechaCrea: new Date(),
                    fechaVence:  dueDate,
                    usuarioID: localStorage.getItem('user'),
                } 
                };
        }

        //////// Create Reposicion on Grafiflex Interactivo internal DB
        let cot;
        try { 
            //console.log('NEW PARAMS', newParams);
            cot = await next(resource, newParams, options);
        } catch (error) {
            console.error('ERROR', error);
            notify(`Error Creando Reposicion ${error.message}`, {type:'error'}, { smart_count: 1 });
            return
        }   

        //////// Modify Old OP to make it not visible
        try{
            const testing = false;  // true if u dont want to update the sqlOrdenOut table
            if(!testing){
                if (data?.ordenes) {
                    for (const orden of data.ordenes) {
                        await dataProvider.update('sqlOrdenCotizOut', {filter: {numero: orden.numero, versionop: orden.version, nrocotiza: newParams.data.consecutivo}});
                    }
                }
            }
        } catch (error) {
            console.error('ERROR', error);
            notify(`Error Modificando Orden (OP Antigua) ${error.message}`, {type:'error'}, { smart_count: 2 });
        }   

        ////// Discount from Stock
        try{
            discountFromStock(data.itemsFact);
        }
        catch(error){
            console.error('ERROR', error);
            notify(`Error Actualizando Stock: ${error.message}`, {type:'error'}, { smart_count: 2 });
         return;
        }

        //////// Create Product Facts
        try{
            const res = await Promise.all(
                newParams.data.itemsFact.map(async item => {
                  const cotiza = await cot;
                  const newItem = { ...item, vendedorID: cotiza.vendedorID, ciudad:cotiza.ciudadId, cotizaID: cotiza.id, cotizaNro: cotiza.consecutivo, clienteID:cotiza.clienteId, clienteName: cotiza.clienteName};
                  
                  return dataProvider.create('productFacts', { data: { ...newItem } });
                })
              );
            //console.log('RES', await res);

            } catch (error) {
            console.error('ERROR', error);
            notify(`Error ${error.message}`, {type:'error'}, { smart_count: 3 });
        }

    }, []);

    const discountFromStock = async (items) => {
        //console.log('ITEMS inside cajas', items);
        const cajas = items.reduce((acc, item) => {
            

            const found = acc?.find(it => it.caja === item.caja);
            if (found) {
                if (found.area > 0){
                    found.area += item.area;
                }
            } else {
                acc.push({ caja: item.caja, area: item.area });
            }
            return acc;
        }, []);
        //console.log('CAJAS', cajas);
        for (const caja of cajas) {
            if(caja === null || caja.area<=0 || caja.caja === undefined) continue;
            const stock = await dataProvider.getOne('stocks', { id: caja.caja });
            const newStock = {
                ...stock.data,
                areaRestante: stock.data.areaRestante - caja.area,
            }
            delete newStock.retales
            await dataProvider.update('stocks', { id: caja.caja, data: newStock });
        }
    }
           
        // Use the `useCreate` hook or directly call the `dataProvider` with the prepared JSON
    useRegisterMutationMiddleware(middleware);

 

    return (
        <Toolbar>
            {/*<SaveButton key='borrador' label="Guardar Borrador"  variant="outlined" alwaysEnable />*/}
            <SaveButton
                key={'emitir'}
                label="Crear Cotización"
                alwaysEnable
            />
        </Toolbar>
    );
};





const ReposicionCreate = () => {
    
    
    return (
        <Create>
            <SimpleForm toolbar={<CustomToolbar/>} >
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} container alignItems={'center'}>
                        < Grid item  md={6}>
                            <ReferenceInput source='clienteId' reference='clientes' fullWidth     queryOptions={{ meta: { searchable: true } }}
                            >
                                <AutocompleteInput 
                                    optionText="razonSocial"
                                    label="Cliente"
                                    sx={{ padding: '10px', fontSize: '25px' }} 
                                    fullWidth
                                    filterToQuery={searchText => ({ razonSocial: {matchPhrasePrefix : searchText} })}
                                />
                            </ReferenceInput>
                        </Grid>
                        < Grid item  md={6}>
                            <ReferenceInput source='responsablesID' reference='usuarios' fullWidth label='Responsable'  queryOptions={{ meta: { searchable: true }}}>
                                <AutocompleteArrayInput label='Responsables' 
                                    optionText={(record) => `${record.nombres} ${record.apellidos}`}
                                    sx={{ padding: '10px', fontSize: '25px' }} fullWidth
                                    filterToQuery={searchText => ({ nombres: {matchPhrasePrefix:searchText} })}
                                />
                            </ReferenceInput>
                        </Grid>
                        {/* < Grid item >
                                <ObservacionesCliente />
                        </Grid> */}
                        <Grid item xs={6} md={6}>
                            <SelectInput source="ciudadId" label="Ciudad"  choices={Sucursales} optionText='name' fullWidth size="large"/>
                        </Grid>
                            {/* < Grid item  md={6}>
                                <ReferenceInput source='sqlOrdens' reference='usuarios' fullWidth label='Ordenes Asociadas' >
                                    <AutocompleteArrayInput label='Ordenes Asociadas' 
                                        optionText={(record) => `${record.numero}-${record.versionop}`}
                                        sx={{ padding: '10px', fontSize: '25px' }} fullWidth
                                        filterToQuery={searchText => ({ numero: {matchPhrasePrefix:searchText} })}
                                    />
                                </ReferenceInput>
                            </Grid> */}

                    </Grid>
                    <Grid item xs={12} md={12}>
                        <EditableTable tableName = 'repo' />
                    </Grid>
                    <Grid item  xs={12} md={12} container  direction={'row'} justifyContent={'flex-end'} >
                        <Grid item xs={12} md={7}>
                            <TextInput source="observaciones"  fullWidth/>
                        </Grid>
                        {/* <TotalesComponent changed={refetchData}/> */}
                    </Grid>
                </Grid>
            </SimpleForm>
            {/* <OrdenesPendientesShow/> */}
        </Create>
    );
};




export default ReposicionCreate;








