import { post } from "aws-amplify/api";
import { Auth } from "aws-amplify/auth";
import { Pagination } from "./Pagination";
import sql from 'mysql-bricks'
import { Filter } from "./Filter";
const sortOperators = ["eq", "le", "lt", "ge", "gt", "beginsWith"];
const filterOperators = ["eq", "le", "lt", "ge", "gt", "beginsWith", "like"];





export class AlegraProvider {

  static async getList(
    resource,
    params
  ){
    console.log("GET LIST ALEGRA PROVIDER", resource, params);
    // remove alegra prefix
    const resourceNew = resource.replace("alegra", "").toLowerCase();;
   
    const { data } = await this.post(`/alegra/getList/${resourceNew}`, resource ,params);
    //console.log("GET LIST RESPONSE Alegra Provider", data);
    if( !data ){
      return { data: [], total: 0 };
    }
    if( data.data && data.metadata ){
    const total = data.metadata.total;
    const resData = data.data;
      return {
        data: resData,
        total: total,
      };
    }else{
      return {
        data: data,
        total: data.length,
      };
    }
  }

  static async getMany(
    resource,
    params
  ){
    const resourceNew = resource.replace("alegra", "").toLowerCase();

    if( params && params.ids && params.ids.length > 0 ){
      //console.log("GET MANY", resource, params);
      const queriesData = [];
      for (let i = 0; i < params.ids.length; i++) {
        const { pagination, sort } = params;
        const filter  = {id: params.ids[i]}
        const  { data } = await this.post(`/alegra/getOne/${resourceNew}`, resource, filter)
        queriesData.push(await data);
      }

       //console.log("GET MANY RESPONSE", queriesData);
      return {
        data: queriesData,
      };
    }
  }

  static async create(
    resource,
    params
  ){
    const resourceNew = resource.replace("alegra", "").toLowerCase();

    // console.log("CREATE", resource, params);

    const  { data } = await this.post(`/alegra/create/${resourceNew}`, resource, {...params.data} )
    // console.log("CREATE RESPONSE", data);

    return {
      data: data,
    };  
  };
  static async preview(
    resource,
    params
  ){
    const resourceNew = resource.replace("alegra", "").toLowerCase();

    // console.log("PREVIEW", resource, params);

    const  { data } = await this.post(`/alegra/preview/${resourceNew}`, resource, {...params.data} )
    //console.log("PREVIEW RESPONSE", data);

    return {
      data: data,
    };  
  }
  static async email(
    resource,
    params
  ){
    const resourceNew = resource.replace("alegra", "").toLowerCase();

    // console.log("EMAIL", resource, params);

    const  { data } = await this.post(`/alegra/email/${resourceNew}`, resource, {...params})
    // console.log("EMAIL RESPONSE", data);

    return {
      data: 'Email sent',
    };  
  }

  static async getOne(
    resource,
    params
  ){
    if( params && params.id  ){
      const resourceNew = resource.replace("alegra", "").toLowerCase();
      const { data } = await this.post(`/alegra/getOne/${resourceNew}`, resource, {...params})
      return { data: data };
    }
  }
  static async delete(
    resource,
    params
  ){
    // console.log("DELETE", resource, params);
    if( params && params.id  ){
      const resourceNew = resource.replace("alegra", "").toLowerCase();
      const { data } = await this.post(`/alegra/delete/${resourceNew}`, resource, {...params})
      return { data: data };
    }
  }
  static async update(
    resource,
    params
  ){
    // console.log("UPDATE", resource, params);
    if( params && params.id  ){
      const resourceNew = resource.replace("alegra", "").toLowerCase();
      const { data } = await this.post(`/alegra/update/${resourceNew}`, resource, {...params})
      return { data: data };
    }
  }


  static async post(path, resource, params) {
    const init = {
      headers: {
        "Content-Type": "application/json",
      },
      body: {resource: resource, params: params},
    };
    try {
      const result = await post({
        apiName: "AlegraConnect",
        path,
        options: init
      });
      //console.log("POST RESPONSE result", result);
      const res = await result.response; 
      //console.log("POST RESPONSE", res);
      if (res.statusCode === 200) {
        //console.log("POST RESPONSE INNSIDE STATUS 200", res);
        const data = await res.body.json(); 
        //console.log("DATA", data);
        return { data };
      } else {
        console.error("POST ERROR", res);
        return { error: `Request failed with status ${res.statusCode}` };
      }
    } catch (error) {
      console.error("POST EXCEPTION", error);
      throw error; // Or handle it as you see fit, possibly returning an error response
    }
  }
  
  }

