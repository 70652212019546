import './App.css';
import { Amplify } from 'aws-amplify';
import { buildAuthProvider, LoginPage, buildDataProvider } from './Amplify'
import { Admin, localStorageStore , ListGuesser, EditGuesser} from 'react-admin'
import { Resource } from "@react-admin/ra-rbac";
import  administrator  from './admin'
import facturacion from './facturacion'
import  orden from './orden'
import { MarcacionList, TurnosList, HuellasList, PeriodosEdit, PeriodosList} from './marcaciones'
import polyglotI18nProvider from "ra-i18n-polyglot";
import spanishMessages from "./utils/dict";
import produccion from "./produccion"
import { grey } from '@mui/material/colors';
import { defaultTheme } from 'react-admin';
import product from "./productos"
import * as mutations from './graphql/mutations'
import * as queries  from './graphql/queries'
import * as subscriptions from './graphql/subscriptions';


import config from './amplifyconfiguration.json';
import LayoutCont from './Main/containers/Layout'
import {
  CognitoUserList,
  CognitoUserShow,
} from "./Amplify/components/CognitoUser";
import { SqlOrdenesList } from './archivo/OrdenesList';


Amplify.configure(config);

const store = localStorageStore();
//store.setItem('sidebar.open', false);


const theme = {
  ...defaultTheme,
  palette: {
    primary:{main: "#2c2c2c" },
    secondary: {main: "#212121"},
    action: {  hover: 'rgba(0,166,214,0.07)' },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: { // Assuming you want to style the root
          width: 42,
          height: 26,
          padding: 0,
          margin: 10,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: '#65C466',
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: '#33cf4d',
              border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              //color: grey[theme.palette.mode === 'light' ? 100 : 600],
              color: grey[100],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity:  0.7 ,
              //opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
          },
          '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor:  '#E9E9EA',
            //backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            
          },
        },
      },
    },
  },
  }


const messages = {
  es: spanishMessages,
};
export const i18nProvider = polyglotI18nProvider(() => (spanishMessages)  , 'es', {allowMissing: true});


const authProvider = buildAuthProvider({ authGroups: ["root", "admin", "guest", "inventarios", "facturacion", "produccion"] })
const dataProvider = buildDataProvider(
                                        {queries, mutations, subscriptions},
                                        {
                                        authMode: ['API_KEY'], 
                                        storageBucket: config.aws_user_files_s3_bucket,
                                        storageRegion: config.aws_user_files_s3_bucket_region,
                                        enableAdminQueries: true})

// // ENABLE THIS FOR TESTING
// console.log= function() {}


const App = () => (
<Admin  
      authProvider={authProvider}
      requireAuth
      loginPage={LoginPage}
      dataProvider={dataProvider}
      layout={LayoutCont}
      theme={theme} 
      store={store}
      i18nProvider={i18nProvider}>
      <Resource name="sqlSalidas" list={facturacion.salidas.list}/>     
      <Resource name="cognitoUsers" options={{ label: "Cognito Users" }} list={CognitoUserList} show={CognitoUserShow} />
      <Resource name="clientes" list={administrator.customerList} create={administrator.customerCreate}  edit={administrator.customerEdit} />
      <Resource name="alegraClientes" list={administrator.AlegraCustomerList} />
      <Resource name="alegraInvoices" list={facturacion.alegraInvoice.list} />
      <Resource name="alegraCreditNotes" list={facturacion.alegraCreditNotes.list} />
      <Resource name="contactoClientes" />
      <Resource name="usuarios" list={administrator.UserList} create={administrator.UserCreate} edit={administrator.UserEdit}/>
      <Resource name="materials" create={administrator.MaterialCreate} edit={administrator.MaterialEdit} list={administrator.MaterialList} show={administrator.MaterialShow}/>
      <Resource name="stocks" create={administrator.StockCreate} edit={administrator.StockEdit} list={administrator.StockList} />
      <Resource name="productos" list={product.list}  />
      
      <Resource name="marcas"/>
      <Resource name="ordens" list={orden.list} edit={orden.edit} create={orden.create}/>
      <Resource name="marcacions" list={MarcacionList}/>
      <Resource name="turnos" list={TurnosList} />
      <Resource name="periodos" list={PeriodosList} edit={PeriodosEdit} />
      <Resource name="huellas" list={HuellasList} />
      <Resource name="sqlOrdenes" list={SqlOrdenesList} edit={EditGuesser} />
      <Resource name="sqlplanchasop" list={ListGuesser} edit={EditGuesser} />
      <Resource name="exposicions" list={produccion.ExposicionList} create={produccion.ExposicionCreate} show={produccion.ExposicionShow}/>
      <Resource  name="remissions" create={facturacion.remision.create} list={facturacion.remision.list} edit={facturacion.remision.edit}/>     
      <Resource  name="facturas" create={facturacion.factura.create} list={facturacion.factura.list} show={facturacion.factura.show}/>     
      <Resource  name="cotizacions" create={facturacion.cotizacion.create} list={facturacion.cotizacion.list} edit={facturacion.cotizacion.edit}/>     
      <Resource  name="reposicions" create={facturacion.reposicion.create} list={facturacion.reposicion.list} edit={facturacion.reposicion.edit}/>     
      <Resource  name="productFacts" list={facturacion.reportes.list} />     
      <Resource  name="salidas"/>
      <Resource name="salidaOrdens" list={produccion.SalidaOrdenList} create={produccion.SalidaOrdenCreate} show={produccion.SalidaOrdenShow} edit={produccion.SalidaOrdenEdit}/>
      {/* <Resource  name="facturas"/>      */}
      <Resource name='userExposicions' list={produccion.UserExposicionList} create={produccion.UserExposicionCreate} show={produccion.UserExposicionShow}/>
      <Resource name="ordenSalidas" list={produccion.OrdenSalidaList} create={produccion.OrdenSalidaCreate} show={produccion.OrdenSalidaShow}/>
      <Resource name="cajas"  list={produccion.CajaList} create={produccion.CajaCreate }show={produccion.CajaShow}/>
    </Admin>
);




export default App;


