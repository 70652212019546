
import React, { useEffect } from 'react';
import { Datagrid, DateField, FunctionField, List, NumberField, TextField, useListContext, useRecordContext, useGetList, Pagination } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import { useUnselectAll, FilterList, FilterListItem,Button } from 'react-admin';
import { SalidasActions } from './SalidasActions';
import { Table, TableBody, TableCell, TableHead, TableRow, Box, Grid, TextField as TextFieldStandalone} from '@mui/material';
import ChatBubble from '../../utils/chatBubble';

const ListPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;



export const SqlSalidasList = () => {
    const { data: vendedores, isLoading } = useGetList('usuarios', {filter: {}, pagination:{page:1,perPage:1000}} );
    //console.log('Vendedores', vendedores);
    const vendedoresConAlegra = vendedores?.filter(item => item.alegraVendedorId);

    //console.log('Vendedores Con Alegra', vendedoresConAlegra);
    
    return (
    <List resource="sqlSalidas" aside={<FilterSideBar/> } perPage={100} title={'Salidas'} pagination = {<ListPagination />} >
        <Datagrid expand={<Details/>}  expandSingle bulkActionButtons={<SalidasActions vendedores={vendedoresConAlegra}/>} >
            <NumberField source="NUMERO" label='OP'/>
            <NumberField source="VERSIONOP"  label="Version" />
            <TextField source="CLIENTE" label="Cliente"/>
            <TextField source="TRABAJO" label="Nombre Trabajo" />
            <TextField source="CIUDAD" label="Ciudad"/>
            <TextField source="TOTALPLAN" label="Planchas"/>
            <FunctionField  label="Tipo Trabajo" render={record=>{
                let tipoTrabajo = '';
                if(record.FOTOPOLIM === 1){
                    tipoTrabajo += 'Fotopolimero';
                }
                if(record.PRUEBA === 1){
                    if(tipoTrabajo !== ''){
                        tipoTrabajo += ' / ';
                    }
                    tipoTrabajo += 'Prueba de Contrato';
                    if (record.CONPLANCHAS === 1){
                        tipoTrabajo += ' (Enviar con Planchas)';
                    }
                } 
                if(record.ARTE === 1){
                    if(tipoTrabajo !== ''){
                        tipoTrabajo += ' / ';
                    }
                    tipoTrabajo += 'Arte';
                }
                if(record.REPOSICION === 1){
                    if(tipoTrabajo !== ''){
                        tipoTrabajo += ' / ';
                    }
                    tipoTrabajo += 'REPOSICIÓN';
                }
                return tipoTrabajo;

            }}/>
            <DateField source="SALIDAPRODUCCION" label="Salida Produccion" showTime={true} 
                        transform={(value) =>{
                            const date = new Date(value);
                            return(
                            new Date(date.getTime() + (300 * 60 * 1000))
                            )       }                 
                        }
                    
            />
            <VendedorField source="DISENHAFAC" vendedores={vendedores}label="Vendedor"/>
        </Datagrid>
    </List>
);
}


const VendedorField = ({source, vendedores}) => {
    const record = useRecordContext();
    const vendedor = vendedores?.filter(vendedor => vendedor.cedula === record[source]);

    if (vendedor?.length === 0 || !vendedor) {
        return '';
    }else{
        return <div>{vendedor[0]?.nombres + ' ' + vendedor[0]?.apellidos}</div>
    }
}

const ciudadesMap ={
    "Mede": "ENVIGADO",
    "Barr": "BARRANQUILLA",
    "Cali": "CALI",
}
const FilterSideBar = (props) => {
    const { data, filterValues, setFilters } = useListContext();

    const unselectAll = useUnselectAll('sqlSalidas');

    const clientes = removeDuplicates(data, 'CLIENTE'); 
    const ciudades = removeDuplicates(data, 'CIUDAD');
    useEffect(() => {
        if (filterValues) {
            unselectAll()
            }
        else{
        }
    }, [filterValues, unselectAll]);
    const [innerFilter, setInnerFilter] = React.useState('');
    const handleInnerFilter = (e) => {
        setInnerFilter(e.target.value);
    };

    return (
    <Card sx={{ order: -1, mr: 2, mt: 9, width: 250 }}>
        <CardContent>  
        {
        data&&data.length===0?
        
            <Button onClick={()=>setFilters({})}>Limpiar Filtros</Button>
        
        :(<>
        
            <FilterList label="Ciudad" >{
                ciudades?ciudades.map((item)=>(
                    <FilterListItem sx={{RaFilterListItem:{fontSize:'10px'}}} key={item.CIUDAD} label={ciudadesMap[item.CIUDAD]}  value={{ CIUDAD: item.CIUDAD }} />
                )): <FilterListItem  label="Sin Ciudades" />
            }
            </FilterList> 
            <FilterList label="Clientes" >
                <TextFieldStandalone  label="Buscar" onChange={handleInnerFilter} size='small'/>
                {
                clientes?clientes.map((item)=>{
                    if(item.CLIENTE.toLowerCase().includes(innerFilter.toLowerCase())){
                        return(
                            <FilterListItem key={item.CLIENTE} label={item.CLIENTE}  value={{ CLIENTE: item.CLIENTE }} />
                        )
                    }
                }): <FilterListItem  label="Sin Clientes Pendientes" />
            }
            </FilterList>
            </>
        )  }
        </CardContent>
    </Card>
    )
}
const removeDuplicates = (data, uniqueKey) => {
    return data?data.reduce((accumulator, current) => {
      if (!accumulator.some((item) => item[uniqueKey] === current[uniqueKey])) {
        if(current[uniqueKey]){
            accumulator.push(current);
        }
      }
      return accumulator;
    }, []):[];
  };



  const Details = () => {
    const record = useRecordContext();

    const filterPlanchas = {
            and: [
                { ORDEN: record.NUMERO },
                { VERSIONOP: record.VERSIONOP }
            ]
    };
    const filterObservaciones = {
        NUMERO: record.NUMERO,
        VERSIONOP: record.VERSIONOP
    }

    console.log('filterObservaciones', filterObservaciones);
    
    const { data: planchas, isLoading: loadingPlanchas} = useGetList('sqlplanchasop', {filter:filterPlanchas});
    const { data: observaciones, isLoading: loadingObserv} = useGetList('sqlObservaciones', {filter:filterObservaciones});
    
    if (loadingPlanchas || loadingObserv) {
        return <p>Loading...</p>;
    }


    const merged =  planchas.reduce((acc, item) => {
            const found = acc.find(accItem =>   (accItem.ANCHO === item.ANCHO || accItem.ANCHO === item.AVANCE) && 
                                                (accItem.AVANCE === item.AVANCE || accItem.AVANCE === item.ANCHO) && 
                                                accItem.NROCAJA === item.NROCAJA);
            if (found) {
                found.CANTIDAD += item.CANTIDAD;
                found.AREA = found.ANCHO * found.AVANCE * found.CANTIDAD;
            } else {
                item.AREA = item.ANCHO * item.AVANCE * item.CANTIDAD;
                acc.push({ ...item });
            }
            return acc;
        }, []);


    return (    
        <Grid container minWidth='100%' spacing={3} justifyItems={'space-between'}>
            <Grid item  md={12} lg={4}  container >
                <Box  sx={{ margin: '1em' , minWidth:'100%'}}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Ancho</b></TableCell>
                                <TableCell><b>Largo</b></TableCell>
                                <TableCell><b>Cantidad</b></TableCell>
                                <TableCell><b>Caja</b></TableCell>
                                <TableCell><b>Área</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {merged?.map((item, idx) => (
                                <TableRow key={idx}>
                                    <TableCell>{item.ANCHO}</TableCell>
                                    <TableCell>{item.AVANCE}</TableCell>
                                    <TableCell>{item.CANTIDAD}</TableCell>
                                    <TableCell>{item.NROCAJA}</TableCell>
                                    <TableCell>{item.AREA}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell colSpan={2}  ><b>Total</b></TableCell>
                                <TableCell colSpan={2}  > {merged.reduce((acc, item) => acc + item.CANTIDAD, 0)} </TableCell>
                                <TableCell>{merged?.reduce((acc, item) => acc + item.AREA, 0)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            </Grid>

            <Grid item  xs ={12} sm ={12} md={12} lg={8} container>
                    <Box sx={{ margin: '1em', minWidth: '100%' }}>
                        <Grid item md={12} container spacing={2} direction='row' justifyContent={'flex-start'}  sx={{ minWidth: '100%' }}>
                        {observaciones?.map((observation, idx) => (
                            <Grid item  md={12} lg={6} >
                                <ChatBubble key={idx} observation={observation} />
                            </Grid>
                        ))}
                        </Grid>
                    </Box>
            </Grid>
        </Grid> 
            
    )
}