import { Typography, Grid } from '@mui/material';

import Divider from '@mui/material/Divider';

import React from 'react'
import {
        ArrayInput, ReferenceInput,
        SimpleFormIterator,
        NumberInput,
        SelectInput,
        FormDataConsumer,
        TextInput,
        BooleanInput, 
        useGetList,
        AutocompleteArrayInput} from 'react-admin';
import {TiposOrdenes,TiempoPago, RegimenAdq, valoresPredeterminados, Retenciones} from '../../../utils'
import { useFormContext, useWatch } from 'react-hook-form';


export const Billing = (props)=>{
    const { setValue } = useFormContext();
    const reteIcaBool = useWatch({name: 'datosFacturacion.reteIcaBool'});
    return (
    <Grid  container direction='column' minHeight='300'>
        <Grid item xs={12} container direction='row' justifyContent='flex-start' alignItems='center' spacing={4}>
            <Grid item md={2} sm={12}>
                <Typography justify='right' variant ='h5'> General </Typography>
            </Grid>
            <Grid item md={2} sm={6}><SelectInput fullWidth variant='outlined' source='datosFacturacion.formaPago'  label = 'Tiempo de Pago' choices={TiempoPago}/></Grid>
            <Grid item md={3} sm={6}><SelectInput fullWidth variant='outlined' source='datosFacturacion.regimenAdq' label='Régimen' choices={RegimenAdq} /></Grid>
            <Grid item md={1} sm={4}><BooleanInput variant='outlined' source='datosFacturacion.iva' label = 'Facturar con Iva' /></Grid>
            <Grid item md={1} sm={4}><BooleanInput source='datosFacturacion.variacionTRM' label='Variación TRM'/></Grid>
            <Grid item md={1} sm={4}><BooleanInput source='datosFacturacion.validarCxC' label='Validar CxC'/></Grid> 
            <Grid item md={1} sm={6}><NumberInput source='datosFacturacion.sobremedida' label='Sobremedida en CM' defaultValue={2}/></Grid>      
            <Grid item md={1} sm={6}><BooleanInput source='cotiza' label='Cotiza' /></Grid>      
        </Grid>

        <Divider variant='middle'/>

        <RetencionesInput newSource='datosFacturacion.retenciones' />
        <RetencionesInput2 newSource='datosFacturacion.retenciones' />

        <Divider variant='middle'/>

        <Grid item xs={12} container direction='row' justifyContent='flex-start' spacing={3} alignItems='center'>
            <Grid item md={3} sm={12}>
                <Typography  justify='right' variant ='h5'> E-mail Facturación Electrónica</Typography>
            </Grid>
            <Grid item md={6} sm={12}><TextInput variant='outlined' fullWidth source = 'datosFacturacion.emailFE' label='Email' sx={{marginTop:'8px'}} /> </Grid>
        </Grid>

        <Divider variant='middle'/>

        <Grid item xs={12} container direction='row' justifyContent='flex-start' spacing={3} alignItems='center'>
            <Grid item md={12} sm={12}>
                <Typography  justify='center' variant ='h5'>Precios</Typography>
            </Grid> 
            <Grid item xs={12} md={6} >
                <ArrayInput source='datosFacturacion.precioOrden' label='Precios Ordenes por Tipo' >
                    <SimpleFormIterator inline fullWidth>
                            <SelectInput variant='outlined' source='tipoOrden' label='Orden' choices={TiposOrdenes} size='small'/>
                        <FormDataConsumer>
                                        {({
                                            formData, 
                                            scopedFormData, // The data for this item of the ArrayInput
                                            getSource, // A function to get the valid source inside an ArrayInput
                                            index,
                                            ...rest
                                            }) => 
                                            scopedFormData ?
                                            (TiposOrdenes.find( ( element ) => element.id === scopedFormData.tipoOrden) ?(    
                                                <NumberInput variant='outlined'  source={getSource('valor')}  label='Valor'sx={{mt:'8px'}}/>   
                                            ) : (null))
                                            : null  }
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
            </Grid>
            <Grid item xs={12} md={6} justifyContent={'space-between'}>
                <TextInput source='observaciones' multiline fullWidth sx={{ "& .MuiOutlinedInput-input": {minHeight: "100px"}}}/>
            </Grid>
        </Grid>
    </Grid>
)};

const SubProductosArrayForm = (props)=>{

    const {
        newSource,
        prodType
    }= props    
    return(
    <FormDataConsumer>
        {({ getSource}) => 
            (   
            <ArrayInput source={newSource} label='' >
                <SimpleFormIterator inline>
                    <SelectInput variant='outlined' source={getSource('tipo')} label='Dificultad' choices={TiposOrdenes.find( ( element ) => element.id === prodType).valores} />
                    <NumberInput variant='outlined'  source={getSource('valor')} label='Valor' />
                </SimpleFormIterator>
            </ArrayInput>  
            )
        }
    </FormDataConsumer>
    )
}

// const useStyles = makeStyles(theme => ({
//     root: {
//         minWidth:'100%',
//         marginBottom: theme.spacing(1),
//         variant: 'outlined'
//     },
// }));



const RetencionesInput = (props) => {
    const { setValue } = useFormContext();
    const reteIcaBool = useWatch({name: 'datosFacturacion.reteIcaBool'});
    
    const { newSource } = props;
    return (
        <Grid item xs={12} container direction='row' justifyContent='flex-start' spacing={3} alignItems='center'>
            <Grid item md={2} sm={12}>
                <Typography  justify='right' variant ='h5'> Retenciones </Typography>
            </Grid>
            <Grid item md={4} sm={12} container  direction='row' spacing={1} justifyContent='flex-start'alignItems='center'>
                <Grid item  md={9}>
                    <SelectInput variant='outlined' fullWidth source='datosFacturacion.retefuenteType'
                    choices={[
                        {id:'servicios', name:'Servicios'},
                        {id:'compras', name: 'Compras'}
                    ]}
                    label='Compras/Servicios'
                    onChange={(event)=>(setValue('datosFacturacion.retefuentePorc', valoresPredeterminados[event?.target?.value] || '' ))}
                    />
                </Grid>
                <Grid item md={3}><NumberInput variant='outlined' source='datosFacturacion.retefuentePorc' label='%'/></Grid>
            </Grid>
            <Grid item sm={4} md={2}><BooleanInput variant='outlined' source='datosFacturacion.reteIvaBool' label='Retiene IVA'/></Grid>
            <Grid item sm={4} md={2} ><BooleanInput variant='outlined' source='datosFacturacion.reteIcaBool' label='Retiene ICA %'/></Grid>
            {
            reteIcaBool ? (

            <Grid item sm={4} md={2} ><NumberInput variant='outlined' source='datosFacturacion.reteIcaPorc' label='ReteICA %'/></Grid>  
            )
            : <span></span>
            }
        </Grid> 
    )
}


const RetencionesInput2 = (props) => {
    const { setValue } = useFormContext();
    const retenciones = useGetList('alegraRetentions');
    const handleValueAdd = (v)=>{
        console.log('Value Added', v);
        //console.log('Retenciones', retenciones);
        // if(retenciones.status === 'success'){
        const retArray = []
        for (let index = 0; index < v.length; index++) {
            
            const retAlegra = retenciones?.data.filter((element)=>element.id === v[index])[0];
            const retencion = {
                id: retAlegra.id,
                name: retAlegra.name,
                type: retAlegra.type,
                percentage: parseFloat(retAlegra.percentage),
              }
            console.log('Retencion', retencion);
            retArray.push(retencion);
        }
        setValue('datosFacturacion.retenciones', retArray);
    }
    return (
        <Grid item xs={12} container direction='row' justifyContent='flex-start' spacing={3} alignItems='center'>
            <Grid item md={2} sm={12}>
                <Typography  justify='right' variant ='h5'>  </Typography>
            </Grid>
            <Grid item md={4} sm={12}>
                <AutocompleteArrayInput onChange={(v)=>handleValueAdd(v)} source='datosFacturacion.retencionesIDs' choices={retenciones && retenciones.data ?retenciones.data:[]} optionText={record=> `${record.name} ${parseFloat(record.percentage)}%`||''}  label='Retenciones Alegra' />
            </Grid>
        </Grid> 
    )
}

