
import {useListContext, useRecordContext} from 'ra-core'
import get from 'lodash/get';

import {
    List, 
    Datagrid,
    TextField,
    SelectField,
    SearchInput,
    SelectInput,
    BooleanInput,
    ArrayField,
    SingleFieldList,
    ChipField,
    downloadCSV,
} from 'react-admin'
import { Sucursales } from '../../utils'
import jsonExport from 'jsonexport/dist';

const ListFilter =  [
    <SearchInput source='q' alwaysOn />,
  <SelectInput source='sucursalEncargada.eq' label='Sede' choices={Sucursales} />,
    <BooleanInput
      key={3}
      defaultValue={false} 
      source="cotiza.eq"
      label="Cotiza"
      />
]


const AlegraCustomerList = () => {

    return(
    <List title='Clientes'
          filters={ListFilter} 
          sort={{ field: 'razonSocial', order: 'ASC'}}
         perPage={50}  
         queryOptions={{meta:{searchable:'true', filterable: ['razonSocial', 'nit_cedula' ]}}} 
         debounce={1000}
         exporter={exporter}
         >
        <Datagrid rowClick="edit">
            <TextField source="razonSocial" label='Razón Social'/>
            <TextField source="nit_cedula" label='NIT'/>
            <TextField source="direccion" label='Dirección'/>
            <ArrayField source='datosFacturacion.precioOrden' label='Precio Orden'>
                <SingleFieldList linkType={false}>
                        <ChipField source="valor" size="small" />
                </SingleFieldList>
            </ArrayField>
            <SelectField source="sucursalEncargada" label='Sede' choices={Sucursales}/>
        </Datagrid>
    </List>
    );
}


const tipoOrdenValues = [
    'ARTE_ALTA',
    'ARTE_MEDIA',
    'ARTE_BAJA',
    'FINALIZACION_ALTA',
    'FINALIZACION_BAJA',
    'OPENPRINT',
    'COPIADO',
    'REPETICION',
    'PRUEBA_CONTRATO',
    'MONTAJE',
    'MAQUILA',
    'LEN',
    'POSITIVO_NEGATIVO',
    'OTRO'
];

const exporter = records => {
    console.log("exporter", records);
    const recordExport = records.map(record => {
        const { id, nit_cedula, razonSocial, sucursalEncargada, datosFacturacion } = record;
        const precioOrden = datosFacturacion.precioOrden?.reduce((acc, item) => {
            acc[`${item.tipoOrden}`] = item.valor;
            return acc;
        }, {}) || {};

        // Ensure all tipoOrden values are included, even if they are not present in the record
        tipoOrdenValues.forEach(tipo => {
            if (!precioOrden.hasOwnProperty(`${tipo}`)) {
                precioOrden[`${tipo}`] = null;
            }
        });
        const emails = get(datosFacturacion, 'emailFE', []);
        return {
            id,
            nit_cedula,
            razonSocial,
            emails,
            sucursalEncargada,
            ...precioOrden,
        };
    });

    jsonExport(recordExport, {
        headers: ['id', 'nit_cedula', 'razonSocial', 'emails', 'sucursalEncargada',  ...tipoOrdenValues.map(tipo => `${tipo}`)] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'precioClientes'); // download as 'clientes.csv` file
    });
};



export default AlegraCustomerList