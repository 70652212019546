
import * as React from 'react';
import { useState } from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { IfCanAccess } from "@react-admin/ra-rbac";
import TodayIcon from '@mui/icons-material/Today';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import FlareIcon from '@mui/icons-material/Flare';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import { styled } from '@mui/system';
import ArchiveIcon from '@mui/icons-material/Archive';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AlegraIcon from "../../facturacion/components/alegraIcon";
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import {
    useTranslate,
    MenuItemLink,
    useSidebarState
} from 'react-admin';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import admin from '../../admin';
import orden from '../../orden'
import SubMenu from './SubMenu';

//const MenuName = 'menuAdmin' | 'menuOrden' ;

const CustomMenu = ({ dense = false }) => {
    const [state, setState] = useState({
        menuAdmin: false,
        menuOrden: false,
        menuProduct:false,
        menuTimes: false,
        menuProduccion: false,
        menuArchivo: false,
        menuFacturacion: false,
        menuInventory: false
    });
    const translate = useTranslate();
    const [open] = useSidebarState();
    
    //useSelector((state) => state.theme); // force rerender on theme change

    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    const RootDiv = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    width: open ? 200 : 45, // `open` needs to be passed or managed differently
    }));

    return (
        <RootDiv>
            <IfCanAccess action='read' resource='AdminMenu'>
                <SubMenu
                    handleToggle={() => handleToggle('menuAdmin')}
                    isOpen={state.menuAdmin}
                    name='Administración'
                    icon={<admin.icons.main/>}
                    dense={dense}
                >    
                
                <IfCanAccess action="list" resource='clientes'>
                    <MenuItemLink
                        to={{
                            pathname: '/clientes',
                            state: { _scrollToTop: true },
                        }}
                        primaryText={translate(`Clientes`, {
                            smart_count: 1,
                        })}
                        leftIcon={<admin.icons.customer />}
                        dense={dense}
                    />  
                </IfCanAccess>  
                <IfCanAccess action="list" resource='alegraClientes'>
                                    <MenuItemLink
                                        to={{
                                            pathname: '/alegraClientes',
                                            state: { _scrollToTop: true },
                                        }}
                                        primaryText={translate(`Clientes Alegra`, {
                                            smart_count: 1,
                                        })}
                                        leftIcon={<PersonPinIcon/>}
                                        dense={dense}
                                    />  
                                </IfCanAccess>
                <IfCanAccess action="list" resource='usuarios'>
                    <MenuItemLink
                        to={{
                            pathname: '/usuarios',
                            state: { _scrollToTop: true },
                        }}
                        primaryText={translate(`Usuarios`, {
                            smart_count: 2,
                        })}
                        leftIcon={<admin.icons.user />}
                        dense={dense}
                    />  
                    </IfCanAccess> 
                    <IfCanAccess action="list" resource='cognitoUsers'>
                        <MenuItemLink
                            to={{
                                pathname: '/cognitoUsers',
                                state: { _scrollToTop: true },
                            }}
                            primaryText={translate(`Usuarios Inscritos`, {
                                smart_count: 3,
                            })}
                            leftIcon={<admin.icons.cognitoUsers/>}
                            dense={dense}
                        />
                    </IfCanAccess>
                </SubMenu>
            </IfCanAccess>
            <IfCanAccess action='read' resource='inventarioMenu'>
                <SubMenu
                    handleToggle={() => handleToggle('menuInventory')}
                    isOpen={state.menuInventory}
                    name='Inventario'
                    icon={<admin.icons.inventory/>}
                    dense={dense}
                >    
                
                <IfCanAccess action="list" resource='materials'>
                    <MenuItemLink
                        to={{
                            pathname: '/materials',
                            state: { _scrollToTop: true },
                        }}
                        primaryText={translate(`Materiales`, {
                            smart_count: 1,
                        })}
                        leftIcon={<admin.icons.materiales />}
                        dense={dense}
                    />  
                </IfCanAccess>  
                <IfCanAccess action="list" resource='stocks'>
                    <MenuItemLink
                        to={{
                            pathname: '/stocks',
                            state: { _scrollToTop: true },
                        }}
                        primaryText={translate(`Stock`, {
                            smart_count: 1,
                        })}
                        leftIcon={<admin.icons.stock />}
                        dense={dense}
                    />  
                </IfCanAccess>  
                
                </SubMenu>
            </IfCanAccess>
            <IfCanAccess action='list' resource='productos'>
                <SubMenu
                    handleToggle={() => handleToggle('menuProduct')}
                    isOpen={state.menuProduct}
                    name='Productos'
                    icon={<PictureAsPdfIcon/>}
                    dense={dense}
                >
                        <MenuItemLink
                            to={{
                                pathname: '/productos',
                                state: { _scrollToTop: true },
                            }}
                            primaryText={translate(`Productos`, {
                                smart_count: 4,
                            })}
                            leftIcon={<PictureAsPdfIcon/>}
                            dense={dense}
                        />  
                </SubMenu>
            </IfCanAccess> 
            <IfCanAccess action='list' resource='ordens'>
                <MenuItemLink
                        to={{
                            pathname: '/ordens',
                            state: { _scrollToTop: true },
                        }}
                        primaryText={translate(`Ordenes`, {
                            smart_count: 5,
                        })}
                        leftIcon={<orden.icons.pendientes/>}
                        dense={dense}
                    /> 
            </IfCanAccess>
            <IfCanAccess action='list' resource='Horarios'>
                <SubMenu
                    handleToggle={() => handleToggle('menuTimes')}
                    isOpen={state.menuTimes}
                    name='Horarios'
                    icon={<TodayIcon/>}
                    dense={dense}
                >    
                
                <IfCanAccess action="list" resource='marcacions'>
                    <MenuItemLink
                        to={{
                            pathname: '/marcacions',
                            state: { _scrollToTop: true },
                        }}
                        primaryText={translate(`Marcaciones`, {
                            smart_count: 6,
                        })}
                        leftIcon={<AlarmOnIcon/>}
                        dense={dense}
                    />  
                </IfCanAccess>  
                <IfCanAccess action="list" resource='turnos'>
                    <MenuItemLink
                        to={{
                            pathname: '/turnos',
                            state: { _scrollToTop: true },
                        }}
                        primaryText={translate(`Turnos`, {
                            smart_count: 7,
                        })}
                        leftIcon={<WorkHistoryIcon />}
                        dense={dense}
                    />  
                    </IfCanAccess> 
                <IfCanAccess action="list" resource='Periodos'>
                    <MenuItemLink
                        to={{
                            pathname: '/periodos',
                            state: { _scrollToTop: true },
                        }}
                        primaryText={translate(`Periodos`, {
                            smart_count: 7,
                        })}
                        leftIcon={<DateRangeIcon/>}
                        dense={dense}
                    />  
                </IfCanAccess> 
                <IfCanAccess action="list" resource='huellas'>
                    <MenuItemLink
                        to={{
                            pathname: '/huellas',
                            state: { _scrollToTop: true },
                        }}
                        primaryText={translate(`Huellas`, {
                            smart_count: 8,
                        })}
                        leftIcon={<FingerprintIcon />}
                        dense={dense}
                    />  
                </IfCanAccess>     
                </SubMenu>
            </IfCanAccess>
            <IfCanAccess action='list' resource='produccion'>
                <SubMenu
                    handleToggle={() => handleToggle('menuProduccion')}
                    isOpen={state.menuProduccion}
                    name='Produccion'
                    icon={<PrecisionManufacturingIcon/>}
                    dense={dense}
                >
                    <IfCanAccess action='list' resource='exposicions'>
                        <MenuItemLink
                            to={{
                                pathname: '/exposicions',
                                state: { _scrollToTop: true },
                            }}
                            primaryText={translate(`Exposiciones`, {
                                smart_count: 9,
                            })}
                            leftIcon={<FlareIcon/>}
                            dense={dense}
                        />
                    </IfCanAccess>
                    <IfCanAccess action='list' resource='ordenSalidas'>
                        <MenuItemLink
                            to={{
                                pathname: '/ordenSalidas',
                                state: { _scrollToTop: true },
                            }}
                            primaryText={translate(`Orden Salida`, {
                                smart_count: 10,
                            })}
                            leftIcon={<AssignmentLateOutlinedIcon/>}
                            dense={dense}
                            />  
                    </IfCanAccess>
                    <IfCanAccess action='list' resource='salidaOrdens'>
                        <MenuItemLink
                            to={{
                                pathname: '/salidaOrdens',
                                state: { _scrollToTop: true },
                            }}
                            primaryText={translate(`Salida Orden`, {
                                smart_count: 11,
                            })}
                            leftIcon={<ShoppingCartCheckoutIcon/>}
                            dense={dense}
                            />  
                    </IfCanAccess>
                    <IfCanAccess action='list' resource='remisions'>
                        <MenuItemLink
                            to={{
                                pathname: '/remisions',
                                state: { _scrollToTop: true },
                            }}
                            primaryText={translate(`Remisiones`, {
                                smart_count: 11,
                            })}
                            leftIcon={<ReceiptLongIcon/>}
                            dense={dense}
                        />  
                    </IfCanAccess>
                </SubMenu>
            </IfCanAccess> 
            <IfCanAccess action='list' resource='Archivo'>
                <SubMenu
                    handleToggle={() => handleToggle('menuArchivo')}
                    isOpen={state.menuArchivo}
                    name='Archivo'
                    icon={<ArchiveIcon/>}
                    dense={dense}
                >
                        <MenuItemLink
                            to={{
                                pathname: '/sqlOrdenes',
                                state: { _scrollToTop: true },
                            }}
                            primaryText={translate(`Ordenes`, {
                                smart_count: 12,
                            })}
                            leftIcon={<orden.icons.pendientes/>}
                            dense={dense}
                        />  
                </SubMenu>
            </IfCanAccess> 
            
            <IfCanAccess action='list' resource='facturacionMenu'>
                <SubMenu
                    handleToggle={() => handleToggle('menuFacturacion')}
                    isOpen={state.menuFacturacion}
                    name='Facturacion'
                    icon={<AccountBalanceIcon/>}
                    dense={dense}
                >   
                    <IfCanAccess action='list' resource='sqlSalidas'>
                        <MenuItemLink
                            to={{
                                pathname: '/sqlSalidas',
                                state: { _scrollToTop: true },
                            }}
                            primaryText={translate(`Salidas`, {
                                smart_count: 13,
                            })}
                            leftIcon={<ShoppingCartCheckoutIcon/>}
                            dense={dense}
                        />  
                    </IfCanAccess>
                    <IfCanAccess action='list' resource='remissions'>
                        <MenuItemLink
                            to={{
                                pathname: '/remissions',
                                state: { _scrollToTop: true },
                            }}
                            primaryText={translate(`Remisiones`, {
                                smart_count: 14,
                            })}
                            leftIcon={<ReceiptLongIcon/>}
                            dense={dense}
                        />  
                    </IfCanAccess>
                    <IfCanAccess action='list' resource='cotizacions'>
                        <MenuItemLink
                            to={{
                                pathname: '/cotizacions',
                                state: { _scrollToTop: true },
                            }}
                            primaryText={translate(`Cotizaciones`, {
                                smart_count: 15,
                            })}
                            leftIcon={<ReceiptIcon/>}
                            dense={dense}
                        />  
                    </IfCanAccess>
                    <IfCanAccess action='list' resource='facturas'>
                        <MenuItemLink
                            to={{
                                pathname: '/facturas',
                                state: { _scrollToTop: true },
                            }}
                            primaryText={translate(`Facturas`, {
                                smart_count: 16,
                            })}
                            leftIcon={<RequestQuoteIcon/>}
                            dense={dense}
                        />      
                    </IfCanAccess>    
                    {/* <IfCanAccess action='list' resource='facturasAlegra'>
                        <MenuItemLink
                            to={{
                                pathname: '/alegraInvoices',
                                state: { _scrollToTop: true },
                            }}
                            primaryText={translate(`Facturas Alegra`, {
                                smart_count: 17,
                            })}
                            leftIcon={<AlegraIcon/>}
                            dense={dense}
                        />      
                    </IfCanAccess>     */}
                    
                    <IfCanAccess action='list' resource='creditNotes'>
                        <MenuItemLink
                            to={{
                                pathname: '/alegraCreditNotes',
                                state: { _scrollToTop: true },
                            }}
                            primaryText={translate(`Notas Credito`, {
                                smart_count: 17,
                            })}
                            leftIcon={<AssignmentReturnIcon/>}
                            dense={dense}
                        />      
                    </IfCanAccess>    
                    <IfCanAccess action='list' resource='productFacts'>
                        <MenuItemLink
                            to={{
                                pathname: '/productFacts',
                                state: { _scrollToTop: true },
                            }}
                            primaryText={translate(`Reportes`, {
                                smart_count: 17,
                            })}
                            leftIcon={<AssessmentIcon/>}
                            dense={dense}
                        />  
                    </IfCanAccess>     
                    <IfCanAccess action='list' resource='reposicions'>
                        <MenuItemLink
                            to={{
                                pathname: '/reposicions',
                                state: { _scrollToTop: true },
                            }}
                            primaryText={translate(`Reposiciones`, {
                                smart_count: 17,
                            })}
                            leftIcon={<BrokenImageIcon/>}
                            dense={dense}
                        />      
                    </IfCanAccess>                                   
                </SubMenu>
            </IfCanAccess> 
            
        </RootDiv>
    );
};


export default CustomMenu;



