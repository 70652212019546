import React, { useEffect, useState } from 'react';
import { List, Datagrid, TextField, DateField, TextInput, TopToolbar, CreateButton,
    useRecordContext, Pagination, useDataProvider, ChipField, WrapperField, Form, 
    NumberField, useRedirect, useListContext, SearchInput, useNotify, useRefresh, 
    Button, 
    FunctionField} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { IconButton } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import DownloadingIcon from '@mui/icons-material/Downloading';
import { DeleteCotizacion } from './DeleteCotizacion';
import { generatePDF } from '../functions/generateCotizacionPDF';
import EditIcon from '@mui/icons-material/Edit';
import StatusFilter from './StatusFilter';
import SyncIcon from '@mui/icons-material/Sync';


import AgregarPagoButton from './AgregarPagoButton'; 
import Summary from './Summary';

import { Chip } from '@mui/material';


const CotizacionFilters = [
    <SearchInput source='q' alwaysOn />,
    <StatusFilter   source="estado" label= "Estado" alwaysOn />
    //<TimezoneAdjustedDateInput label="Fecha" source="fechaCrea" alwaysOn />
]

const ListPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

const Filterable = ['consecutivo.eq.int', 'clienteName' ];
const Aggregations = [
    {field:'valorPendiente', name:'Pendiente', type:'sum'},
    {field:'total', name:'TotalPesos', type:'sum'},
    {field:'totalArea', name:'TotalArea', type:'sum'},
]

const CotizacionList = () => (
    <List   filters={CotizacionFilters} 
            sort={{field: 'fechaCrea', order: 'DESC'}}
            debounce={1000} 
            //actions={<ListActions/>} 
            pagination = {<ListPagination />} 
            title={'Cotizaciones'} 
            perPage={50} 
            queryOptions={{meta:{searchable:'true', filterable: ['consecutivo.eq.int', 'clienteName' ]}}} 
            aside={<Summary filterable = {Filterable} agg={Aggregations} resource='cotizacions'/>}>
        <Datagrid >
            <TextField source="consecutivo" label='Número'/>
            <TextField source="clienteName" label='Cliente' />
            <DateField source="fechaCrea" label='Fecha Creación'/>
            <FunctionField source="fechaCrea" label='Días' render={(record)=>(Math.abs(Math.floor((new Date() - new Date(record.fechaCrea)) / (1000 * 60 * 60 * 24))))} />
            <StatusField  />
            <NumberField source="totalArea" locales="es-CO"  />
            <NumberField source="valorPendiente" locales="es-CO" options={{ style: 'currency', currency: 'COP',maximumFractionDigits: 0 }} />
            <NumberField source="total" locales="es-CO" options={{ style: 'currency', currency: 'COP',maximumFractionDigits: 0 }} />
            <WrapperField label="Acciones">
                <AgregarPagoButton />
                <ImprimirButton />
                <EditButtonIcon />
                <DeleteCotizacion type='icon' />
            </WrapperField>
        </Datagrid>
    </List>
);






const EditButtonIcon = () => {
    const redirect = useRedirect();
    const record = useRecordContext();

    return(
        <IconButton
            variant='outlined'
            onClick={() => {
                redirect(`/cotizacions/${record.id}`);
            }}
        >
            <EditIcon/>
        </IconButton>
    );
}

const StatusField = () => {       
    const record = useRecordContext();
    if (record.estado === "Cobrada") {
        return <ChipField source="estado" color="success" variant='outlined' />;
    }
    if (record.estado === "Abonada") {
        return <ChipField source="estado" color="warning" variant='outlined' label="Estado" />;
    }
    if (record.estado === "Editada") {
        return <ChipField source="estado"  color="info"   variant='outlined' />;
    }
    return <ChipField source="estado"  color="primary" variant='outlined'/>;
};

const ImprimirButton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false); 
    
    //console.log('RECORD', record)

    return(
    <IconButton
        variant='outlined'
        onClick={async () => {
            setLoading(true);
            const res = await dataProvider.getOne('clientes', { id: record.clienteId })
            // set client info to generate PDF
            
            if(res.data){
                const client = {name : res.data.razonSocial, identification: res.data.nit_cedula, address:{address: res.data.direccion}, phonePrimary: res.data.telefono1}
                setLoading(false);
                generatePDF(record, client);
                }
            }

        }
    >{

        loading ?  <DownloadingIcon/> :
        <PrintIcon/>
    }
    </IconButton>
);
}



const ListActions = () => {
    const { data } = useListContext();
    //console.log('DATA', data);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleUpdate = async () => {
        // Filter the data to get only the elements with estado "emitida"
        const emitidaData = data.filter(item => item.estado === "Emitida" || item.estado === "Editada" );
        // Loop through the emitidaData and update the estado to "Facturada" if the status is "closed"
        for (const item of emitidaData) {
            try {
                    item.valorPendiente = item.total;
                    //console.log('ITEM', item)
                    const { productFact, ...updatedItem } = item;

                    await dataProvider.update('cotizacions', {
                        id: updatedItem.id,
                        data: {  ...updatedItem },
                        previousData: item,
                    });
                    refresh();
                    console.log('Estado actualizado', item.consecutivo);
                }
            catch (error) {
                console.error('ERROR', error);
                notify('Error: no se pudo actualizar el estado', { type: 'error' });
            }
        }
    }



    return(
    <TopToolbar>
        <Button startIcon={<SyncIcon/>} onClick={handleUpdate} size='small'>
            Actualizar Estado
        </Button>
    </TopToolbar>
);

}





export default CotizacionList;