import React from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { formatCurrencyString } from "../../utils";
import { num2Str } from './num2str';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const generatePDF = (data, client) => {
  const allItems = data.itemsFact || [];
  // const allItems = items4.slice(0,37)|| [];
  const totalItems = allItems.length;


  ///////////////////////////WORKING NOW MAKE IT WORK FOR REMISIONESS
  // Predefined capacity rules:
  // Page 1 with summary: 13 items max
  // Page 2 with summary: 18 items max (if we start summary on page 2)
  // Page 2 without summary: 25 items (if summary goes later)
  
  // Adjust these numbers and conditions based on your layout experiments.
  const firstPagewithSummary = 13;//antes ok ok ok
  const firstPagewithoutSummary = 18;
  const secondPagewithSummary = 36;
  const secondPagewithoutSummary = 43;


  let firstPageItems = [];
  let secondPageItems = [];
  let restItems = []; // if you have more pages, extend logic similarly

  let placeSummaryOnFirstPage = false;
  let placeSummaryOnSecondPage = false;


  let applyEmptyRows1 = 0;
  let applyEmptyRows2 = 0;

  if (totalItems <= firstPagewithSummary) {
    //case everything in first page
    placeSummaryOnFirstPage = true;
    firstPageItems = allItems;
    applyEmptyRows1 = totalItems < firstPagewithSummary ? firstPagewithSummary-totalItems : 0;

  } else if (totalItems > firstPagewithSummary && totalItems <= firstPagewithoutSummary) {

    //console.log('case when summary goes on second page but no close to third page' )

    placeSummaryOnSecondPage = true;
    firstPageItems = allItems.slice(0, firstPagewithSummary);
    secondPageItems = allItems.slice(firstPagewithSummary);
    applyEmptyRows2 = totalItems < secondPagewithSummary ? secondPagewithSummary - totalItems-5 : 0;

  } else if (totalItems > firstPagewithoutSummary && totalItems <= secondPagewithSummary) {
    //console.log('case when summary goes on second page without forcing it to third' )

    // case when summary goes on second page without forcing it to third
    firstPageItems = allItems.slice(0, firstPagewithoutSummary-2);
    secondPageItems = allItems.slice(firstPagewithoutSummary-2);
    placeSummaryOnSecondPage = true;
    applyEmptyRows2 = totalItems < secondPagewithSummary ? secondPagewithSummary - totalItems-2 : 0;

  } else if (totalItems > secondPagewithSummary && totalItems <= secondPagewithoutSummary) {
    //console.log('case when summary goes to third page but no close to end third page' )

    // case when summary goes on second page without forcing it to third
    firstPageItems = allItems.slice(0, firstPagewithoutSummary);
    secondPageItems = allItems.slice(firstPagewithoutSummary, totalItems-3 );
    restItems = allItems.slice(totalItems-3);
    placeSummaryOnSecondPage = false;
  } else {
    firstPageItems = allItems.slice(0, firstPagewithoutSummary);
    restItems = allItems.slice(firstPagewithoutSummary);
  }

  const rowsFirstPage = generateRows(firstPageItems, applyEmptyRows1);
  const rowsSecondPage = generateRows(secondPageItems, applyEmptyRows2);
  const rowsRest = generateRows(restItems);

  const summaryStack = {
    table: {
      widths: ['*', '25%'],
      body: [
        [{
          text: num2Str(data.total),
          fillColor: 'lightgray',
          fontSize: getSummaryFontSize(num2Str(data.total)),
          border: [false, false, false, false],
          margin: [5, 0, 0, 0],
          height: 10
        },
        {
          text: `Area: ${data.totalArea} cm²`,
          fillColor: 'lightgray',
          style:'bold',
          border: [false, false, false, false],
          margin: [0, 0, 0, 0],
          height: 10
        }]
      ]
    },
    layout: 'noBorders'
  };

  const observationsAndTotal = {
    columns: [
      {
        table: {
          widths: ['*'],
          heights: [20, 10],
          body: [
            [{
              text: data.observaciones || ' ',
              fontSize: 8,
              border: [false, false, false, true],
              alignment: 'left',
              margin: [0, 0, 0, 0],
            }],
            [{text: 'Observaciones', style: 'subheader', alignment: 'center', border: [false, false, false, false]}]
          ]
        },
      },
      {
        table: {
          widths: ['*'],
          heights: [20, 10],
          body: [
            [{
              text: ' ',
              border: [false, false, false, true],
              alignment: 'left',
              margin: [0, 0, 0, 0],
            }],
            [{text: 'Recibido', style: 'subheader', alignment: 'center', border: [false, false, false, false]}]
          ]
        },
      },
      {
        stack: [
          { columns: [{ text: "Total: ", style: 'subheader', alignment: 'right' }, { text: formatCurrencyString(data.total), alignment: 'left' }] }
        ],
        width: 181
      }
    ],
    margin: [0, 10, 0, 0],
    columnGap: 10
  };

  const summaryBlock = {
    keepTogether: true,
    stack: [summaryStack, observationsAndTotal]
  };

  // Build content based on these rules
  const content = [
    {
      columns: [
        { text: 'Cotización', fontSize: 26, style: 'bold' },
        {
          columns: [
            { width: '*', text: '' },
            {
              stack: [
                { text: `No. ${data.consecutivo}`, style: 'header' },
                { text: `Fecha: ${new Date(data.fechaCrea).toLocaleDateString('es-CO')}` },
                { text: `Vence: ${new Date(data.fechaVence).toLocaleDateString('es-CO')}` }
              ],
              alignment: 'center'
            }
          ]
        }
      ]
    },
    {
      columns: [
        {
          width: 200,
          text: [
            { text: 'Cliente:\n', style: 'bold' },
            { text: client.name, style: 'bold', fontSize: client.name.length > 27 ? (client.name.length > 35 ? 8.5 : 9) : 10 }
          ]
        },
        {
          width: '*',
          text: [
            { text: 'Nit.\n', style: 'bold' },
            { text: client.identification, fontSize: client.name.length > 27 ? (client.name.length > 35 ? 8.5 : 9) : 10 }
          ]
        },
        {
          width: 200,
          text: [
            { text: 'Dirección\n', style: 'bold' },
            {
              text: `${client.address?.address || ' '}\n`,
              fontSize: (client.address?.address?.length > 27 || client.name.length > 27)
                ? ((client.address?.address?.length > 35 || client.name.length > 35) ? 8.5 : 9)
                : 10
            }
          ]
        },
        {
          width: 'auto',
          text: [
            { text: 'Tel:\n', style: 'bold' },
            { text: client.phonePrimary || ' ', fontSize: client.name.length > 27 ? (client.name.length > 35 ? 8.5 : 9) : 9 }
          ]
        }
      ]
    },
    {
      canvas: [
        {
          type: 'line',
          x1: 0, y1: 0,
          x2: 8.5*25.4*2.835 - 15 - 15,
          y2: 0,
          lineWidth: 5,
          lineColor: 'lightgray'
        }
      ],
      margin: [0,10,0,10]
    }
  ];

  // Page 1 items
  if (rowsFirstPage.length > 0) {
    content.push(createTableBlock(rowsFirstPage));
    if (placeSummaryOnFirstPage) {
      content.push(summaryBlock);
    }
  }

  // Page 2 items
  if (rowsSecondPage.length > 0) {
    // Force a new page if needed
    content.push({ text: ' ', pageBreak: 'before' });
    content.push(createTableBlock(rowsSecondPage));
    if (placeSummaryOnSecondPage) {
      content.push(summaryBlock);
    }
  }

  // Page 3 (rest) items
  if (rowsRest.length > 0) {
    content.push({ text: '', pageBreak: 'before' });
    content.push(createTableBlock(rowsRest));
    // Decide if summary goes here based on your rules
    if (!placeSummaryOnFirstPage && !placeSummaryOnSecondPage) {
      content.push(summaryBlock);
    }
  }

  const docDefinition = {
    pageSize: { height: 5.5*25.4*2.835, width: 8.5*25.4*2.835 },
    pageOrientation: 'landscape',
    pageMargins: [15, 10, 15, 25],
    footer: function(currentPage, pageCount) {
      return { 
        text: `Cotización No. ${data.consecutivo}         P. ${currentPage} / ${pageCount}`, 
        alignment: 'right', 
        margin:[0, 0, 10, 0], 
        color: 'grey', 
        fontSize: 6 
      };
    },
    content: content.flat(), // flatten in case createTableBlock returns arrays
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        backgroundColor: 'gray',
        margin: [0,0,0,0]
      },
      observaciones: {
        fontSize: 7,
        alignment: 'justify',
        margin: [0,0,0,0]
      },
      subheader: {
        fontSize: 13,
        bold: true,
        margin: [0,0,0,0]
      },
      tableHeader: {
        bold: true,
        fontSize: 10,
        color: 'black'
      },
      bold: {
        bold: true
      },
      tableContent: {
        fontSize: 8,
        margin: [0,0,0,0]
      }
    }
  };

  pdfMake.createPdf(docDefinition).download(`C${data.consecutivo}-${client.name.slice(0,8)}.pdf`);
};

function generateRows(items, applyEmptyRows = 0) {
  const rows = items.map(item => [
    { text: item.ordenVersion || '', style: 'tableContent' },
    { text: (item.odc && item.odc.trim()) || ' ', style: 'tableContent' },
    { text: (item.caja ? item.caja : (item.material||'')).slice(0,11), style: 'tableContent' },
    { text: truncateDescription(item.descripcion), style: 'tableContent' },
    { text: item.cantidad || '', style: 'tableContent' },
    { text: (item.ancho && item.largo) ? `${item.ancho}x${item.largo}` : '', style: 'tableContent' },
    { text: item.area || '', style: 'tableContent' },
    { text: item.valorUnitario || '', style: 'tableContent' },
    { text: formatCurrencyString(item.valorTotal), style: 'tableContent' }
  ]);

 
  if (applyEmptyRows>0) {
    //console.log('apply', applyEmptyRows)
    for (let i = 0; i < applyEmptyRows; i++){
      rows.push([
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' },
        { text: ' ', style: 'tableContent' }
    ]);
    }
  }
  //console.log('rows after emptyRows', rows)
 
return rows;


}

function createTableBlock(rows) {
  return {
    table: {
      headerRows: 1,
      widths: [40,30,60,150,30,60,35,45,50],
      body: [
        [
          { text: 'Orden', style: 'tableHeader' },
          { text: 'OdC', style: 'tableHeader' },
          { text: 'Producto', style: 'tableHeader' },
          { text: 'Descripción', style: 'tableHeader' },
          { text: 'Cant', style: 'tableHeader' },
          { text: 'Dimensiones', style: 'tableHeader' },
          { text: 'Area', style: 'tableHeader' },
          { text: 'V. Unit.', style: 'tableHeader' },
          { text: 'Total', style: 'tableHeader' }
        ],
        ...rows
      ]
    },
    layout: 'noBorders'
  };
}

function truncateDescription(desc, maxLen = 30) {
  if (!desc) return ' ';
  return desc.length > maxLen ? desc.slice(0, maxLen) + '...' : desc;
}

function getSummaryFontSize(text) {
  const length = text.length;
  if (length > 90) return 6.5;
  else if (length > 70) return 8;
  return 9;
}