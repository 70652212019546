import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Select,
    IconButton,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Autocomplete,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { type } from '@testing-library/user-event/dist/cjs/setup/directApi.js';


export const CustomEditableTable = ({ columns, initialRows, referenceData, loadingMaterials, setSelectedMaterial, setQueryMaterials }) => {
    const [rows, setRows] = useState(initialRows || []);
    const [isEditing, setIsEditing] = useState(null);
    const [isCreating, setIsCreating] = useState(null);
    const [editRow, setEditRow] = useState({});
    const [isAdding, setIsAdding] = useState(false);

    const handleEdit = (index) => {
        setIsEditing(index);
        setEditRow({ ...rows[index] });
        setSelectedMaterial({rowIndex: index, field: 'material', value: rows[index].material});
        // change valorTotal when editing 
    };

    const handleSave = (index) => {
        setRows((prevRows) => {
            const updatedRows = [...prevRows];
            updatedRows[index] = editRow;
            return updatedRows;
        });
        setIsEditing(null);
        setEditRow({});
        setIsAdding(false);
        setSelectedMaterial({});
    };
    const handleClose = (index) => {
        setIsEditing(null);
        setEditRow({});
        setIsAdding(false);
        setSelectedMaterial({});
    };

    const handleAddRow = () => {
        setRows([{}, ...rows]);
        setIsAdding(true);
    };

    useEffect(() => {
        if (isAdding) {
            setIsEditing(0);
            setEditRow({ ...rows[0] });
            }
    }, [isAdding]);



    const handleDelete = (index) => {
        setRows(rows.filter((_, i) => i !== index));
        setIsAdding(false);
    };

    const renderInput = (col, value, onChange, row, creating) => {

        if (row.type === 'fotopolimero' || row.type === 'prueba') {
            switch (col.type) {
                case 'text':
                    return (
                        <TextField
                            label={col.header}
                            value={value || ''}
                            onChange={(e) => onChange(e.target.value)}
                            size="small"
                            sx={{marginLeft: '5px', marginRight: '5px', width:"95%"}}
                        />
                    );
                case 'number':
                    return (
                        col.editable ?
                        <TextField
                            label={col.header}
                            type="number"
                            value={value || ''}
                            onChange={(e) => onChange(e.target.value)}
                            size="small"
                            sx={{marginLeft: '5px', marginRight: '5px'}}
                        />: <div style={{margin:'10px'}}>{value}</div>
                    );
                case 'select':
                    return (
                        <Select
                            label={col.header}
                            value={value || ''}
                            onChange={(e) => onChange(e.target.value)}
                            size="small"
                            sx={{ width: '95%', marginLeft: '5px', marginRight: '5px'}}
                        >
                            {referenceData[col.reference]?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option[col.optionText]}
                                </MenuItem>
                            ))}
                        </Select>
                    );
                case 'autocomplete':
                    return (
                        <Autocomplete
                            label={col.header}
                            sx={{ width: '95%', marginLeft: '5px', marginRight: '5px' }}
                            options={referenceData[col.reference] || []}
                            getOptionLabel={(option) => option[col.optionText] || ''}
                            loading={loadingMaterials}
                            value={
                                referenceData[col.reference]?.find(
                                    (item) => item.id === value
                                ) || null
                            }
                            onChange={(_, newValue) =>
                                onChange(newValue ? newValue : '')
                            }
                            renderInput={(params) => (
                                <TextField {...params} size="small" onChange={(e)=> setQueryMaterials(e.target.value)}/>
                            )}
                        />
                    );
                case 'boolean':
                    return (
                        <FormControlLabel control={
                            <Checkbox
                            labelPlacement="bottom"
                            checked={!!value}
                            onChange={(e) => onChange(e.target.checked)}
                        />}
                        label={col.header}
                        labelPlacement='top'
                        />

                    
                    );
                default:
                    return null;
                }
        } else {
            switch (col.type) {
                case 'text':
                    return (
                        <TextField
                            label={col.header}
                            value={value || ''}
                            onChange={(e) => onChange(e.target.value)}
                            size="small"
                            sx={{marginLeft: '5px', marginRight: '5px', width:"95%"}}
                        />
                    );
                case 'number':
                    console.log('row', row.type);
                    console.log('col', col.field);
                    if ((row.type !== 'fotopolimero' || row.type !== 'prueba') && (col.field === 'area' || col.field === 'valorTotal' || col.field === 'ancho' || col.field === 'largo')){
                        return null;
                    }
                    return (
                        col.editable ?
                        <TextField
                            label={col.header}
                            type="number"
                            value={value || ''}
                            onChange={(e) => onChange(e.target.value)}
                            size="small"
                            sx={{marginLeft: '5px', marginRight: '5px'}}
                        />: <div style={{margin:'10px'}}>{value}</div>
                    );
                case 'select':
                    if ((row.type !== 'fotopolimero' || row.type !== 'prueba') && (col.field === 'caja')){
                        return null;
                    }
                    return (
                        <Select
                            label={col.header}
                            value={value || ''}
                            onChange={(e) => onChange(e.target.value)}
                            size="small"
                            sx={{ width: '95%', marginLeft: '5px', marginRight: '5px'}}
                        >
                            {referenceData[col.reference]?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option[col.optionText]}
                                </MenuItem>
                            ))}
                        </Select>
                    );
                case 'autocomplete':
                    return (
                        <Autocomplete
                            label={col.header}
                            sx={{ width: '95%', marginLeft: '5px', marginRight: '5px' }}
                            options={referenceData[col.reference] || []}
                            getOptionLabel={(option) => option[col.optionText] || ''}
                            loading={loadingMaterials}
                            value={
                                referenceData[col.reference]?.find(
                                    (item) => item.id === value
                                ) || null
                            }
                            onChange={(_, newValue) =>
                                onChange(newValue ? newValue.id : '')
                            }
                            renderInput={(params) => (
                                <TextField {...params} size="small" onChange={(e)=> setQueryMaterials(e.target.value)}/>
                            )}
                        />
                    );
                case 'boolean':
                    return (
                        <FormControlLabel control={
                            <Checkbox
                            labelPlacement="bottom"
                            checked={!!value}
                            onChange={(e) => onChange(e.target.checked)}
                        />}
                        label={col.header}
                        labelPlacement='top'
                        />
    
                       
                    );
                default:
                    return null;
                }
        }
    };

    const handleEditCell = (rowIndex, field, value) => {
        console.log('rowIndex', rowIndex);
        console.log('field', field);
        console.log('value', value);
      
        if (field === 'material'){
            console.log('value', value);
            setSelectedMaterial({rowIndex: rowIndex, field: field, value: value.id});
            setEditRow((prev) => ({
                ...prev,
                [field]: value.id,
                caja: '',
                type: value.type,
            }));
            return;
        }
        setEditRow((prev) => ({
            ...prev,
            [field]: value,
        }));
    }
    

    return (
        <Box>
            <Table size="small">
                <TableHead >
                    <TableRow >
                        {columns.map((col, index) => (
                            <TableCell
                                key={index}
                                align={col.type === 'number' ? 'right' : (col.type === 'boolean' ? 'center' : 'left')}
                                sx={{ minWidth: col.width ,fontWeight: 'bold'}}
                                padding='none'
                                >
                                    {col.header}
                                
                            </TableCell>
                        ))}
                        <TableCell >
                            <IconButton  variant='contained' onClick={handleAddRow}><AddIcon/></IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {rows.map((row, rowIndex) => (
                        <TableRow key={rowIndex} sx={{ maxHeight: '10px', '&:hover' : {backgroundColor: '#f0fbfd'  } , '&:hover .actions': { visibility: 'visible' } }}>
                            {columns.map((col, colIndex) => (
                                <TableCell 
                                    key={colIndex} 
                                    padding={isEditing===rowIndex? 'none' : 'none'}
                                    align={col.type === 'number' ? 'right' : (col.type === 'boolean' ? 'center':'left')}
                                >
                                    {isEditing === rowIndex ?
                                     renderInput(
                                              col,
                                              editRow[col.field],
                                              (value) => handleEditCell(rowIndex, col.field, value),
                                              row
                                            )
                                        : 
                                        isAdding === rowIndex ?
                                            renderInput(
                                                col,
                                                editRow[col.field],
                                                (value) => handleEditCell(rowIndex, col.field, value),
                                                row
                                            )
                                          :
                                        row[col.field] === true ? 
                                            <CheckIcon/>:
                                            (row[col.field] === false) ? 
                                                <CloseIcon/> : 
                                                (col.field === 'caja') ? 
                                                    row[col.field]?.split('-')[2] :
                                                    <div >{row[col.field]}</div> || ''
                                                    }
                                </TableCell>
                            ))}
                            <TableCell padding='none' className="actions" sx={{ visibility: 'hidden' }}>
                                {isEditing === rowIndex ? (
                                    <>
                                        <IconButton onClick={() => handleSave(rowIndex)}>
                                            <SaveIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleClose(rowIndex)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </>
                                ) : (
                                    <>
                                        <IconButton onClick={() => handleEdit(rowIndex)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleDelete(rowIndex)} color='error'>
                                            <DeleteIcon />
                                        </IconButton>
                                    </>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};

export default CustomEditableTable;




    
    



/*
ssanch30: make an edit row with individual items having the following Table cells:
{ header: 'Orden', field: 'ordenVersion', type: 'text' , width: '50px', editable: true},
        { header: 'OC', field: 'odc', type: 'text', width: '50px' , editable: true},
        { header: 'Descripción', field: 'descripcion', type: 'text' , width: '300px', editable: true},
        { header: 'Producto', field: 'material', type: 'autocomplete', reference: 'materials', optionText: 'id', width: '200px', editable: true},
        { header: 'Caja', field: 'caja', type: 'select', reference: 'stocks', optionText: 'name', width: '50px', editable: true},
        { header: 'Ancho', field: 'ancho', type: 'number' , width: '50px', editable: true},
        { header: 'Largo', field: 'largo', type: 'number' , width: '50px', editable: true},
        { header: 'Cantidad', field: 'cantidad', type: 'number' , width: '50px', editable: true},
        { header: 'Area', field: 'area', type: 'number', width: '50px', editable: false},
        { header: 'V. Unitario', field: 'valorUnitario', type: 'number', width: '50px' , editable: true},
        { header: 'IVA', field: 'iva', type: 'boolean', width: '50px', editable: true },
        { header: 'Total', field: 'valorTotal', type: 'number', width: '50px', editable: false},
        make each component individual to make sure it can be customized individually, the idea is to to make individually customizable

GitHub.copilot-chat.github.copilot.editor: Made changes.

*/
// Example usage:
    // const columns = [
    //     { header: 'Orden', field: 'ordenVersion', type: 'text' },
    //     { header: 'OC', field: 'odc', type: 'text' },
    //     { header: 'Descripción', field: 'descripcion', type: 'text' },
    //     { header: 'Producto', field: 'material', type: 'autocomplete', reference: 'materials', optionText: 'id' },
    //     { header: 'Caja', field: 'caja', type: 'select', reference: 'stocks', optionText: 'id' },
    //     { header: 'Ancho', field: 'ancho', type: 'number' },
    //     { header: 'Largo', field: 'largo', type: 'number' },
    //     { header: 'Cantidad', field: 'cantidad', type: 'number' },
    //     { header: 'V. Unitario', field: 'valorUnitario', type: 'number' },
    //     { header: 'IVA', field: 'iva', type: 'boolean' },
    // ];
// const referenceData = {
//     materials: [{ id: '1', name: 'Material 1' }, { id: '2', name: 'Material 2' }],
//     stocks: [{ id: 'A', name: 'Stock A' }, { id: 'B', name: 'Stock B' }],
// };
// <CustomEditableTable columns={columns} initialRows={[]} referenceData={referenceData} />






// Example usage:
// const columns = [
//     { header: 'Name', field: 'name' },
//     { header: 'Age', field: 'age' },
// ];
// <CustomEditableTable columns={columns} initialRows={[{ name: 'John', age: 30 }]} />




// export const EditableDatagridCustom2 = () => {

//     const record = useRecordContext();
    
//     // console.log('record inside datagrid.id filter', record.id === undefined ? record.previousInvoiceID : record.id);
//     // console.log('record kindofduplicate', record?.kindOfDuplicate);
//     // console.log('record previousInvoiceID', record?.previousInvoiceID);
//     // console.log("record?.kindOfDuplicate &&record?.kindOfDuplicate!=='duplicate'?record.previousInvoiceID:record.id",record?.kindOfDuplicate &&record?.kindOfDuplicate==='duplicate'?record.previousInvoiceID:record.id)
//     const data = getValues('itemsFact');
//     if (isPending) return <p>Cargando...</p>;
//     // console.log('-------------------->data items datagrid show', data);  
//     // console.log('record', record.kindOfDuplicate)

//     return (
//         <Box sx={{ mt: '10px' }}>

//             <Datagrid 
//                 data={data}
//                 bulkActionButtons={false}
//             >
//                 <TextField source='ordenVersion' label='Orden'  />
//                 <TextField source="descripcion" />
//                 <TextField source="caja" />                
//                 <NumberField source="ancho" />
//                 <NumberField source="largo"/>
//                 <NumberField source="cantidad"/>
//                 <NumberField source="area"/>
//                 <NumberField source="valorUnitario" label='V. Unitario'/>
//                 <BooleanField source="iva" />
//                 <NumberField source="valorTotal" label='V. Total' />
//            </Datagrid>
//         </Box>
//     );
// }

// export const EditableDatagridCustom3 = (props) => {

//     const { getValues } = useFormContext();
//     const refresh = useRefresh();
//     const listContext = useList({ data: getValues('itemsFact') });
//     const [changed, setChanged] = useState(false);

//     const handleUpdate = (v)=>
//         {
//             refresh();
//             //console.log('REFRESHING') 
//             setChanged(v);  
//         }
//         useEffect(() => {
//             if (props.refreshDatagrid !== undefined) {
//                 handleUpdate(true); // Trigger refresh when parent toggles refreshDatagrid
//             }
//         }, [props.refreshDatagrid]);
    
//     return (
//     <ListContextProvider value={listContext}>
//         <EditableDatagrid    
//                 size='small'              
//                 createForm={<ItemFormCreate data={listContext.data} changed={(v)=>handleUpdate(v)} />}
//                 editForm={<ItemFormEdit data={listContext.data} changed={(v)=>handleUpdate(v)} />} 
//                 bulkActionButtons={false}
//                 pagination={{page:1, perPage:100}} 
//                 actions={<ActionsEDG/>}
//             >

//                 <TextField source='ordenVersion' label='Orden'  refresh={changed?.toString()}/>
//                 <TextField source="odc"  refresh={changed?.toString()} label='OC'/>
//                 <TextField source="descripcion" refresh={changed?.toString()}/>
//                 <TextField source="material"  refresh={changed?.toString()} label='Producto'/>
//                 <ReferenceField source="caja"  reference='stocks'  label='Caja'>
//                     <TextField source="consecutivo"  refresh={changed?.toString()} />
//                 </ReferenceField>
//                 <NumberField source="ancho"  refresh={changed?.toString()}/>
//                 <NumberField source="largo"  refresh={changed?.toString()}/>
//                 <NumberField source="cantidad"  refresh={changed?.toString()}/>
//                 <NumberField source="area"  refresh={changed?.toString()}/>
//                 <NumberField source="valorUnitario" label='V. Unitario'  refresh={changed?.toString()}/>
//                 <BooleanField source="iva" refresh={props.refreshDatagrid?.toString() || changed?.toString() } />
//                 <NumberField source="valorTotal" label='V. Total' refresh={changed?.toString()}/>
//         </EditableDatagrid>
//     </ListContextProvider>
//     )
// }


// const ItemFormEdit = (props) => {
//     const { close } = useRowContext();
//     const record = useRecordContext();
//     const refresh = useRefresh();
//     const { setValue, getValues } = useFormContext();
//     const indexOfRow = props.data.findIndex(item => item.idItem === record?.idItem);
//     const material = getValues(`itemsFact.${indexOfRow}.material`);
//     const materialId = getValues(`itemsFact.${indexOfRow}.alegraItemId`);
//     const dataProvider = useDataProvider();
//     const [materialName, setMaterialName] = useState(material);
//     const [materialAlegraID, setMaterialAlegraID] = useState(materialId);
//     const [iva , setIva] = useState(getValues(`itemsFact.${indexOfRow}.iva`));
//     const idRow  = getValues(`itemsFact.${indexOfRow}.idItem`)
//     //console.log('IDROW', idRow)
  
//     const handleSubmit = (data) => {
//         data.area = data.ancho * data.largo* data.cantidad;
//         data.valorTotal = data.valorUnitario * data.area;

//         if (data.material?.includes("ARTE") || data.material?.includes("FLETE")){
//             data.valorTotal = data.valorUnitario * data.cantidad;
//             data.area = 0
//             //data.iva = data.material?.includes("FLETES") ? false : iva;
//         }
//         //console.log('DATA edit', data)
//         setValue(`itemsFact.${indexOfRow}.idItem`, idRow);
//         setValue(`itemsFact.${indexOfRow}.ordenVersion`, data.ordenVersion);
//         setValue(`itemsFact.${indexOfRow}.descripcion`, data.descripcion);
//         setValue(`itemsFact.${indexOfRow}.material`, materialName);
//         setValue(`itemsFact.${indexOfRow}.caja`, data.caja);
//         setValue(`itemsFact.${indexOfRow}.ancho`, data.ancho);
//         setValue(`itemsFact.${indexOfRow}.largo`, data.largo);
//         setValue(`itemsFact.${indexOfRow}.cantidad`, data.cantidad);
//         setValue(`itemsFact.${indexOfRow}.valorUnitario`, data.valorUnitario);
//         setValue(`itemsFact.${indexOfRow}.area`, data.area);
//         setValue(`itemsFact.${indexOfRow}.valorTotal`, data.valorTotal);
//         setValue(`itemsFact.${indexOfRow}.iva`, iva);
//         setValue(`itemsFact.${indexOfRow}.alegraItemId`, materialAlegraID);
//         close();
//         props.changed(true);
//     }

//     const filter2query = (filter) => {

//         if (filter){
//             return {id: {contains: filter.toUpperCase()}};
//         }
//         return {};
//     }
//     const handleMaterialChange = async (event) => {
//         if (!event || event === '' ) return;
//         //setValue(`itemsFact.${indexOfRow}.material`, event);
//         setMaterialName(event);
//         await dataProvider.getOne('materials', {id: event}).then((response) => {
//             //setValue(`itemsFact.${indexOfRow}.alegraItemId`, response.data.alegraId);
//             setMaterialAlegraID(response.data.alegraId)
//         });
//         if(event.includes("FLETES")){
//             //setIva(false);
//         }
//         refresh();
//     }
//     return (
//         <RowForm onSubmit={handleSubmit} >
//             <TextInput source='ordenVersion' label='Orden' sx={{width:'95px'}}/>
//             <TextInput source='odc' label='OC'sx={{width:'70px'}}/>
//             <TextInput source="descripcion" fullWidth sx={{width:'150px'}}/>
//             <ReferenceInput source="material" reference="materials" perPage={500}  >
//                 <AutocompleteInput optionText='id' optionValue='id' size='small' label='Producto' filterToQuery={filter2query} onChange={handleMaterialChange}/>
//             </ReferenceInput>
//             {materialName&&(!materialName.includes("ARTE")&&!materialName.includes("FLETES"))?
//             <ReferenceInput source="caja" reference="stocks"  filter= {{and: {activa: {eq: true}, materialID: {eq: materialName}}}}  sort={{field: 'consecutivo', order:'DESC'}} queryOptions={{meta:{searchable:'true'}}} perPage={500} >
//                 <SelectInput optionText='id' optionValue='id' size='small' sx={{width:'50px'}}/>
//             </ReferenceInput>:null}
//             {materialName&&(!materialName.includes("ARTE")&&!materialName.includes("FLETES"))?<NumberInput source="ancho"   sx={{ width: 75 }}/>:null}
//             {materialName&&(!materialName.includes("ARTE")&&!materialName.includes("FLETES"))?<NumberInput source="largo"  sx={{ width: 75 }}/>:null}
//             <NumberInput source="cantidad" sx={{width:'70px'}}/>
//             <NumberInput source="valorUnitario" label='V.Unitario' sx={{width:'100px'}}/>
//             <BooleanInput source="iva" label='IVA'  defaultValue={true} sx={{width:'60px'}}/>
//         </RowForm>
//     );
// };


// const ItemFormCreate = (props) => {
//     const { close } = useRowContext();
//     const refresh = useRefresh();
//     const { setValue, getValues } = useFormContext();
//     const [indexOfRow, setInitialRowIndex] = useState(null);
//     //console.log('INDEX', indexOfRow)
//     useEffect(() => {
//         if(indexOfRow === null){
//             setInitialRowIndex(props.data.length);
//             //console.log('propsdatalength', props.data.length)
//         }
//     }, [indexOfRow])
    
    
//     const fullValues = getValues();

//     //console.log('FULLVALUES', fullValues)
//     const dataProvider = useDataProvider();
//     const [materialName, setMaterialName] = useState('');
//     const [materialAlegraID, setMaterialAlegraID] = useState('');
//     const [iva , setIva] = useState(fullValues.applyIva);


//     const handleSubmit = (data) => {
//         //console.log('DATA create', data)
//         data.area = data.ancho * data.largo* data.cantidad;
//         data.valorTotal = data.valorUnitario * data.area;
//         if (data.material?.includes("ARTE") || data.material?.includes("FLETES")){
//             data.valorTotal = data.valorUnitario * data.cantidad;
//             data.area = 0
//             //data.iva = data.material?.includes("FLETES") ? iva : data.iva;
//         }
//         setValue(`itemsFact.${indexOfRow}.idItem`, uuid());
//         setValue(`itemsFact.${indexOfRow}.ordenVersion`, data.ordenVersion);
//         setValue(`itemsFact.${indexOfRow}.descripcion`, data.descripcion);
//         setValue(`itemsFact.${indexOfRow}.material`, materialName);
//         setValue(`itemsFact.${indexOfRow}.caja`, data.caja);
//         setValue(`itemsFact.${indexOfRow}.ancho`, data.ancho);
//         setValue(`itemsFact.${indexOfRow}.largo`, data.largo);
//         setValue(`itemsFact.${indexOfRow}.cantidad`, data.cantidad);
//         setValue(`itemsFact.${indexOfRow}.valorUnitario`, data.valorUnitario);
//         setValue(`itemsFact.${indexOfRow}.area`, data.area);
//         setValue(`itemsFact.${indexOfRow}.valorTotal`, data.valorTotal);
//         setValue(`itemsFact.${indexOfRow}.iva`, iva);
//         setValue(`itemsFact.${indexOfRow}.alegraItemId`, materialAlegraID);
                
//         close();
//         props.changed(true);
//     }

//     const filter2query = (filter) => {

//         if (filter){
//             return {id: {contains: filter.toUpperCase()}};
//         }
//         return {};
//     }
//     const handleMaterialChange = async (event) => {
//         if (!event || event === '' ) return;
//         //setValue(`itemsFact.${indexOfRow}.material`, event);
//         setMaterialName(event);
//         await dataProvider.getOne('materials', {id: event}).then((response) => {
//             //setValue(`itemsFact.${indexOfRow}.alegraItemId`, response.data.alegraId);
//             setMaterialAlegraID(response.data.alegraId)
//         });

//         if(event.includes("FLETES")){
//             //setIva(false);
//             //setValue(`itemsFact.${indexOfRow}.iva`, false);
//         }
//         //refresh();
//     }
    
//     return (
//         <RowForm onSubmit={handleSubmit}  >
//             <TextInput source='ordenVersion' label='Orden'/>
//             <TextInput source="descripcion" fullWidth/>
//             <ReferenceInput source="material" reference="materials" perPage={500} >
//                 <AutocompleteInput optionText='id' optionValue='id' size='small' label='Producto' filterToQuery={filter2query} onChange={handleMaterialChange} />
//             </ReferenceInput>   
//             {materialName&&(!materialName.includes("ARTE") && !materialName.includes("FLETES"))?
//             <ReferenceInput source="caja" reference="stocks"  filter= {{and: {activa: {eq: true}, materialID: {eq: materialName}}}} sort={{field: 'consecutivo', order:'ASC'}} queryOptions={{meta:{searchable:'true'}}} perPage={500} >
//                 <SelectInput optionText='consecutivo' optionValue='id' size='small'  sx={{width:'50px'}}/>
//             </ReferenceInput>:<></>}
//             {materialName&&(!materialName.includes("ARTE") && !materialName.includes("FLETES"))?<NumberInput source="ancho"   sx={{ width: 100 }}/>:null}
//             {materialName&&(!materialName.includes("ARTE") && !materialName.includes("FLETES"))?<NumberInput source="largo"  sx={{ width: 100 }}/>:null}
//             <NumberInput source="cantidad" sx={{width:'70px'}}/>
//             <NumberInput source="valorUnitario" label='V.Unitario'  sx={{width:'100px'}}/>
//             {materialName ? (!materialName.includes("FLETES"))?<BooleanInput source="iva" label='IVA'  defaultValue={true} sx={{width:'60px'}}/>:<BooleanInput source="iva" label='IVA' defaultValue={false} sx={{width:'60px'}}/>:null}
//         </RowForm>
//     );
// };


// const removeByAttr = (arr, attr, value) => {
//     if (value === undefined) {
//         throw new Error("Value must be provided");
//     }

//     let i = arr.length;
//     while (i--) {
//         if (arr[i] && arr[i].hasOwnProperty(attr) && arr[i][attr] === value) {
//             arr.splice(i, 1);
//         }
//     }
//     return arr;
// }

// const ActionsEDG = () => {
//     const refresh = useRefresh();
//     const record = useRecordContext();
//     const { getValues, setValue } = useFormContext();
//     const data = getValues();
//     const handleDelete = (e) => {
//         const newItems = removeByAttr(data.itemsFact, 'idItem', record.idItem);
//         setValue('itemsFact', newItems);
//         refresh();
//     }
    

//     return (
//     <>
//         <EditRowButton />
//         <DeleteRowItemButton deleteItem={handleDelete} record={record}/>
//     </>
//     )
// }



// const ItemsDatagrid = () => {

//     const record = useRecordContext();
    
//     // console.log('record inside datagrid.id filter', record.id === undefined ? record.previousInvoiceID : record.id);
//     // console.log('record kindofduplicate', record?.kindOfDuplicate);
//     // console.log('record previousInvoiceID', record?.previousInvoiceID);
//     // console.log("record?.kindOfDuplicate &&record?.kindOfDuplicate!=='duplicate'?record.previousInvoiceID:record.id",record?.kindOfDuplicate &&record?.kindOfDuplicate==='duplicate'?record.previousInvoiceID:record.id)
//     const data = getValues('itemsFact');
//     if (isPending) return <p>Cargando...</p>;
//     // console.log('-------------------->data items datagrid show', data);  
//     // console.log('record', record.kindOfDuplicate)

//     return (
//         <Box sx={{ mt: '10px' }}>

//             <Datagrid 
//                 data={data}
//                 bulkActionButtons={false}
//             >
//                 <TextField source='ordenVersion' label='Orden'  />
//                 <TextField source="descripcion" />
//                 <TextField source="caja" />                
//                 <NumberField source="ancho" />
//                 <NumberField source="largo"/>
//                 <NumberField source="cantidad"/>
//                 <NumberField source="area"/>
//                 <NumberField source="valorUnitario" label='V. Unitario'/>
//                 <BooleanField source="iva" />
//                 <NumberField source="valorTotal" label='V. Total' />
//            </Datagrid>
//         </Box>
//     );
// }