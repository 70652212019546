import React, {useState} from 'react';
import ActionDelete from '@mui/icons-material/Delete';

import {
    useRecordContext,
    Confirm, 
    useDataProvider,
    useRefresh,
    useRedirect, 
} from 'react-admin';

import { IconButton, Button } from '@mui/material';

export const DeleteReposicion = (props) => {
    const record = useRecordContext(props);
    const dataProvider = useDataProvider();


    const {
        className,
        confirmTitle = `Eliminar Cotización # ${record?.consecutivo}`,
        confirmContent = 'ra.message.delete_content',
        confirmColor = 'primary',
        icon = defaultIcon,
        label = 'ra.action.delete',
        mutationMode = 'pessimistic',
        onClick,
        redirect = 'list',
        translateOptions = {},
        mutationOptions,
        color = 'error',
        type, 
        ...rest
    } = props;
    
    const redirectTo = useRedirect();
    const [loading, setLoading] = useState(false);

    const [open, setOpen] = React.useState(false);


    const deleteAndRestoreAll = async(props) => {
        setLoading(true);
        try {
            for (const item of record.itemsFact) {
                const resSQLin =  await dataProvider.update('sqlOrdenCotizIn', { filter: { numero: item.orden, versionop: item.version } });
                //console.log('SQLIN', resSQLin)  

              }
            const resStock =  await restoreStock(record.itemsFact);
            ////console.log('STOCK', resStock)
            const res = await dataProvider.delete('cotizacions', { id: record.id , previousData: record});
            const res2 = await Promise.all(
                record.productFact.items.map(async item => {
                    //console.log("ITEM", item)
                        dataProvider.delete('productFacts', { id: item.id });
                    }
            ));
            return true;
        } catch (error) {
            console.error('ERROR', error);
            return false;
        }
    }
    const refresh = useRefresh();

    const handleConfirm = async () => {
        //console.log('RECORD', record)
        const done =  await deleteAndRestoreAll();
        if(done){
            setOpen(false);
            redirectTo('list', '/cotizacions');
            refresh();
            setLoading(false);
        }
    };
    
    const restoreStock = async (items) => {
        const cajas = items.reduce((acc, item) => {

            const found = acc?.find(it => it.caja === item.caja);
            if (found) {
                if (found.area > 0){
                    found.area += item.area;
                }
            } else {
                acc.push({ caja: item.caja, area: item.area });
            }
            return acc;
        }, []);
        let responseArray = []; 
        for (const caja of cajas) {
            //console.log('CAJA', caja)
            if(caja === null || caja.area<=0) continue;
            const stock = await dataProvider.getOne('stocks', { id: caja.caja });
            //console.log('STOCK INSIDE RESTORE', stock)
            const newStock = {
                ...stock.data,
                areaRestante: stock.data.areaRestante + caja.area,
            }
            delete newStock.retales
            const response = await dataProvider.update('stocks', { id: caja.caja, data: newStock });
            responseArray.push(response);
        }
        return responseArray;
    }
    

    return (
        <>{
        type === 'icon' ?
            <IconButton
                variant='outlined'
                onClick={()=>setOpen(true)}
                key="button"
                color={color}
                {...rest}
            >
             {icon}
            </IconButton>
            : 
            <Button
                onClick={()=>setOpen(true)}
                label='ELIMINAR'
                key="button"
                color={color}
                startIcon={icon}
                {...rest}
            >
                ELIMINAR
            </Button>
        }     
            <Confirm
                isOpen={open}
                loading={loading}
                title={confirmTitle}
                content={confirmContent}
                confirmColor={confirmColor}
                onConfirm={handleConfirm}
                onClose={()=>setOpen(false)}

            />
        </>
    );
};

const defaultIcon = <ActionDelete />;


