import React from "react";

import {TextInput, useGetOne,
        ReferenceInput, SelectInput, 
        useRecordContext, FormDataConsumer, BooleanInput} from "react-admin";
import { Box, Grid, Typography } from '@mui/material';

import { useFormContext, useWatch} from 'react-hook-form'



const   CognitoUserInput = ( {props, autoSetParam} ) => {
    const {setValue} = useFormContext()
    const cognitoUserId = useWatch({name:'cognitoUserId'})

    const [ cognito, setCognito ] = React.useState('')
    const [ cognitoSelected, setCognitoSelected ] = React.useState(false)
    const { data } = useGetOne('cognitoUsers', { id:cognitoUserId }, { enabled: cognitoSelected });

    React.useEffect(() => {
        if (cognito && data.email) {
            setValue('email', data.email )
            setValue('id', cognito )
            setValue('nombres', data.name )
            setValue('apellidos', data.family_name )
            autoSetParam(cognitoSelected)
            } 
        
        }, [data]
    );


    const handleCognitoChange = (e)=>{
        if(e.target.value){
            setCognito(e.target.value)
            setCognitoSelected(true)
        }
        else{
            setCognito(e.target.value)
            setCognitoSelected(false)
        }
    }

    
 

    const OptionRenderer =  ( ) => {
        const record = useRecordContext(); 
        return(
            <Grid container direction='row' justifyContent='space-between' spacing={2}>
                <Grid item md={10} zeroMinWidth>
                    <Typography gutterBottom  component={'div'} noWrap style={{'overflowWrap': 'break-word'}}>
                        <Box  sx={{ width:'200px', maxWidth:'300px' ,marginLeft:'2px', marginRight:'5px'}}>{`${record.name} ${record.family_name}`}</Box> 
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography component={'div'}>
                        <Box sx={{fontStyle:'italic', fontWeight:'light', fontSize:'11pt'}}>{`${record.email}` }</Box>
                    </Typography>
                </Grid>                     
            </Grid>
        )}

    return(
    <Grid container direction='row' >    
        <Grid item md={10} >
        <ReferenceInput source='cognitoUserId' reference='cognitoUsers'>
            <SelectInput label='Usuario Cognito' optionText={<OptionRenderer/>} onChange={handleCognitoChange} variant='outlined' size='small' fullWidth/>
        </ReferenceInput>
        </Grid>
        <Grid item md={1}  sx={{paddingLeft:'40px'}}>
            <BooleanInput source='activo' defaultValue={true}/>
        </Grid>
        <Grid item md={12} xs={12}>
            <FormDataConsumer>
                {
                    ({formData, ...rest})=> (formData && formData.email && cognitoSelected ?
                    (
                        <TextInput source='email' disabled={false} variant='outlined' fullWidth/>
                    ):(                    
                        <TextInput source='email' disabled={false} variant='outlined' fullWidth/>
                    )
                )}   
            </FormDataConsumer>
        </Grid>
    </Grid>
    )
}

export default CognitoUserInput